import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useFetch } from 'src/hooks/useFetch';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../EditorNavbar.css';
import { Box, TextField, Typography } from '@mui/material';
import ShareDotModal from './ShareDotModal';
import MultiTagInput from './TagInput';
import { getCurrentUser, isValidEmail } from 'src/utils/helpers';

const ShareModal = ({ script, refreshData }) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [updateScript] = useFetch(`api/scripts/${id}`, ``, '', 'PATCH', false);
  const [open, setOpen] = React.useState(false);

  const [state, setState] = React.useState({
    publicLink: `https://app.screnpla.com/script/${id}`,
    sharedWithEmails: [],
  });

  const [scriptURL, loading] = useFetch('api/scripts/shareScript', ``, '', 'POST', false);
  const user = getCurrentUser();
  const inviteUserToScript = async (tag) => {
    try {
      if (isValidEmail(tag?.name)) {
        const response = await scriptURL({ email: tag?.name, scriptURL: state.publicLink });
        if (response.success) {
          setState({ ...state, sharedWithEmails: [...state.sharedWithEmails, tag] });
          handleUpdateScript('sharedWithEmails', [...state.sharedWithEmails, tag]);
          enqueueSnackbar('Invitation sent successfully', { variant: 'success' });
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      } else {
        enqueueSnackbar('Please enter a valid email address', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Failed to send invitation', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (script) {
      setState({
        publicLink: `https://app.screnpla.com/script/${id}`,
        sharedWithEmails: script?.sharedWithEmails || [],
      });
    }
  }, [script]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateScript = async (chagedKey, changedValue) => {
    try {
      const payload = { ...state };
      if (chagedKey) {
        payload[chagedKey] = changedValue;
      }
      const response = await updateScript({ ...payload });
      if (response) {
        refreshData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <button className="scren-share" onClick={handleClickOpen}>
        share
      </button>
      <Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle />

          <DialogContent>
            <DialogContentText>
              <Typography fontSize={'.85rem'}>Share Script</Typography>
              <Box
                mt={'15px'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                <TextField
                  sx={{ width: '500px' }}
                  id="outlined-basic"
                  label="create new link"
                  variant="outlined"
                  value={state.publicLink}
                  disabled
                />
                {/* COPY ICON HERE */}
                <Box
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(state.publicLink);
                    enqueueSnackbar('Link copied to clipboard', {
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                    });
                  }}
                >
                  <Icon icon="basil:copy-outline" fontSize={'30'} />
                </Box>
              </Box>
              <Box my={'20px'}>
                <MultiTagInput
                  tags={state.sharedWithEmails}
                  onTagAdd={(tag) => {
                    inviteUserToScript(tag);
                  }}
                  placeholder={'Enter names or emails...'}
                  onTagRemove={(tagId) => {
                    setState({
                      ...state,
                      sharedWithEmails: state.sharedWithEmails.filter((item) => item.id !== tagId),
                    });
                    handleUpdateScript(
                      'sharedWithEmails',
                      state.sharedWithEmails.filter((item) => item.id !== tagId)
                    );
                  }}
                />
                <Box>
                  {loading && (
                    <p style={{ color: '#fe6d29', marginBottom: '10px', fontSize: '12px' }}>Sending Invitation...</p>
                  )}
                </Box>
              </Box>
              <Box
                fontSize={'.85rem'}
                width={'500px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Box display={'flex'} alignItems={'center'} gap={'10px'} width={'40%'}>
                  {user?.avatar ? (
                    <Box sx={{ position: 'relative' }}>
                      <img src={user?.avatar} alt="avatar" className="scren-user" />
                      <Box className="scren-userdot" />
                    </Box>
                  ) : (
                    <Box className="scren-user" sx={{ textTransform: 'uppercase' }}>
                      {user?.name?.charAt(0) + user?.name?.charAt(1)}
                      <Box className="scren-userdot" />
                    </Box>
                  )}
                  <p>User</p>
                </Box>
                <Box>
                  <p>Script</p>
                </Box>
                <Box>
                  <p>Owner</p>
                </Box>
                <ShareDotModal />
              </Box>
            </DialogContentText>
          </DialogContent>
          {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Disagree
          </Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
        </Dialog>
      </Box>
    </>
  );
};

export default ShareModal;
