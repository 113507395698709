import React, { useState, useEffect } from 'react';
import { Box, MenuItem, MenuList, Popover, Typography } from '@mui/material';

import '../navbar/EditorNavbar.css';
import Iconify from 'src/components/iconify';

const CourierprimeDropdown = ({
  name,
  option1,
  option2,
  option3,
  option4,
  option5,
  option6,
  option7,
  onChange,
  value,
  onBlur,
}) => {
  const [anchorElCourierprime, setAnchorElCourierprime] = React.useState(null);
  const handleClickCourierprime = (event) => {
    setAnchorElCourierprime(event.currentTarget);
  };
  const handleCloseCourierprime = () => {
    setAnchorElCourierprime(null);
  };
  const openCourierprime = Boolean(anchorElCourierprime);
  const idCourierprime = openCourierprime ? 'simple-popover' : undefined;

  // getting option values

  const [selectedOptionCourierprime, setSelectedOptionCourierprime] = useState(option1); // Initial selected o
  const handleSelectOption = (option) => {
    // Handle the selected option here

    setSelectedOptionCourierprime(option);
    handleCloseCourierprime(); // Close the popover after selecting an option
    if (onChange) {
      onChange(option);
      if (onBlur) {
        onBlur(option);
      }
    }
  };

  useEffect(() => {
    if (value) {
      setSelectedOptionCourierprime(value);
    }
  }, [value]);

  useEffect(() => {
    if (selectedOptionCourierprime) {
      switch (selectedOptionCourierprime) {
        case 'Courier Prime':
          addFontStyleToEditor('CourierPrime');
          break;
        case 'Courier Prime Bold':
          addFontStyleToEditor('CourierPrimeCodeBold');
          break;
        case 'Courier Prime Italic':
          addFontStyleToEditor('CourierPrimeCodeItalic');
          break;
        case 'Courier Prime Sans':
          addFontStyleToEditor('CourierPrimeCodeRegular');
          break;
        default:
          addFontStyleToEditor('CourierPrimeCodeRegular');
          break;
      }
    }

  }, [selectedOptionCourierprime]);

  const addFontStyleToEditor = (fontStyle) => {
       // get all elements with the printablePageShadow class
       const printablePageShadows = document.querySelectorAll('.printablePageShadow');
  
       // Iterate over each element and update the zoom class
       printablePageShadows.forEach((element) => {
          if (element.style.fontFamily.includes(fontStyle)) {
            element.style.fontFamily = '';
            element.style.fontFamily = fontStyle;
          } else {
            element.style.fontFamily = fontStyle;
          }
       });
  }


  return (
    <>
      <Box
        className="scren-zoomlvl"
        aria-describedby={idCourierprime}
        onClick={handleClickCourierprime}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          backgroundColor: '#00017806',
          fontSize: '.75rem',
          padding: ' .25rem .5rem',
          borderRadius: '.25rem',
          width: '100%',
        }}
      >
        <p>{selectedOptionCourierprime}</p>
        <Iconify sx={{ marginLeft: '5px' }} icon="eva:arrow-ios-downward-fill" width={16} height={24} />
      </Box>

      <Popover
        id={idCourierprime}
        open={openCourierprime}
        anchorEl={anchorElCourierprime}
        onClose={handleCloseCourierprime}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            width: '260px',
            maxWidth: 400,
            backgroundColor: '',
            // color: "white",
            padding: '10px',
          }}
        >
          {name === 'dialogue' && (
            <MenuList>
              <MenuItem onClick={() => handleSelectOption(option1)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option1}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option2)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option2}
                </Typography>
              </MenuItem>
            </MenuList>
          )}
          {name === 'courier' && (
            <MenuList>
              <MenuItem onClick={() => handleSelectOption(option1)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option1}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option2)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option2}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option3)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option3}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option4)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option4}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option5)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option5}
                </Typography>
              </MenuItem>
            </MenuList>
          )}

          {
            name === 'export-script' && (
              <MenuList>
                <MenuItem onClick={() => handleSelectOption(option1)}>
                  <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                    {option1}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => handleSelectOption(option2)}>
                  <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                    {option2}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => handleSelectOption(option3)}>
                  <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                    {option3}
                  </Typography>
                </MenuItem>
                </MenuList>
            )
          }

          {name === 'action' && (
            <MenuList>
              <MenuItem onClick={() => handleSelectOption(option1)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option1}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option2)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option2}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option3)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option3}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option4)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option4}
                </Typography>
              </MenuItem>
            </MenuList>
          )}

          {name === 'display' && (
            <MenuList>
              <MenuItem onClick={() => handleSelectOption(option1)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option1}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option2)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option2}
                </Typography>
              </MenuItem>
            </MenuList>
          )}

          {name === 'elementstemplate' && (
            <MenuList>
              <MenuItem onClick={() => handleSelectOption(option1)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option1}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option2)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option2}
                </Typography>
              </MenuItem>
            </MenuList>
          )}

          {name === 'pagelayout' && (
            <MenuList>
              <MenuItem onClick={() => handleSelectOption(option1)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option1}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option2)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option2}
                </Typography>
              </MenuItem>
            </MenuList>
          )}

          {name === 'pagemargins' && (
            <MenuList>
              <MenuItem onClick={() => handleSelectOption(option1)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option1}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option2)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option2}
                </Typography>
              </MenuItem>
            </MenuList>
          )}

          {name === 'sceneheadings' && (
            <MenuList>
              <MenuItem onClick={() => handleSelectOption(option1)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option1}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option2)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option2}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option3)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option3}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option4)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option4}
                </Typography>
              </MenuItem>
            </MenuList>
          )}

          {name === 'spacingbefore' && (
            <MenuList>
              <MenuItem onClick={() => handleSelectOption(option1)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option1}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option2)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option2}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option3)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option3}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option4)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option4}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option5)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option5}
                </Typography>
              </MenuItem>
            </MenuList>
          )}

          {name === 'shortcut' && (
            <MenuList>
              <MenuItem onClick={() => handleSelectOption(option1)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option1}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option2)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option2}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option3)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option3}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option4)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option4}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option5)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option5}
                </Typography>
              </MenuItem>
            </MenuList>
          )}

          {name === 'elementmodeshortcut' && (
            <MenuList>
              <MenuItem onClick={() => handleSelectOption(option1)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option1}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option2)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option2}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option3)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option3}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option4)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option4}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option5)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option5}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option6)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option6}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option7)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option7}
                </Typography>
              </MenuItem>
            </MenuList>
          )}

          {name === 'nextelement' && (
            <MenuList>
              <MenuItem onClick={() => handleSelectOption(option1)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option1}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option2)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option2}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option3)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option3}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option4)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option4}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option5)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option5}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option6)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option6}
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleSelectOption(option7)}>
                <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                  {option7}
                </Typography>
              </MenuItem>
            </MenuList>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default CourierprimeDropdown;
