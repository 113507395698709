/* eslint-disable */
import { useContext } from 'react';
import { HeaderContext } from './HeaderContext';
// @ts-ignore
import { FooterContext } from './FooterContext';
import {
  Editor,
  Range,
  Point,
  Element as SlateElement,
  Transforms,
} from 'slate';

export const Heading = (props) => {
  return (
    <div {...props.attributes} style={{ textTransform: 'uppercase', ...props.style }}>
      {props.children}
    </div>
  );
};

export const Strikeout = (props) => {
  return (
    <div {...props.attributes} style={{ textDecoration: 'line-through', ...props.style }}>
      {props.children}
    </div>
  );
};

export const Action = (props) => {
  return (
    <div {...props.attributes} style={{ ...props.style }}>
      {props.children}
    </div>
  );
};
export const Dialogue = (props) => {
  return (
    <div
      {...props.attributes}
      className="dialogue"
      style={{
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};


export const VoiceMessage = (props) => {
  // Extract the text from the first child node
  const url = props.element?.children[0]?.url;
  return (
    <div
      {...props.attributes}
      className="voice-message"
      style={{
        ...props.style,
      }}
    >
      {/* voice message audio player */}
      <div>
        <audio controls controlsList="nodownload noremoteplayback nofullscreen noplaybackrate">
          <source src={url} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      </div>

      {/* This renders any other children passed */}
      {props.children}
    </div>
  );
};


export const Character = (props) => {
  return (
    <div
      {...props.attributes}
      style={{
        textTransform: 'uppercase',
        // marginLeft: '33%',
        ...props.style,
      }}
      className='textCenter'
    >
      {props.children}
    </div>
  );
};
export const GettingStartedElement = (props) => {
  return (
    <div
      {...props.attributes}
      className="getting-started-element-div"
      style={{
        textTransform: 'uppercase',
        textAlign: 'center !important',
        justifyContent: 'center',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        position: 'relative',
      }}
    >
      {props.children}
    </div>
  );
};
export const Parenthetical = (props) => {
  return (
    <div
      {...props.attributes}
      style={{
        marginLeft: '45%',
        ...props.style,
      }}
    >
      ( {props.children})
    </div>
  );
};
export const Transition = (props) => {
  return (
    <div
      {...props.attributes}
      className="transition"
      style={{
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};
export const PageBreakElement = ({ attributes, children }) => {
  return (
    <span className="page-break" {...attributes} contentEditable={false}>
      <hr />
      {children}
    </span>
  );
};
export const PageNumber = ({ attributes, children }) => {
  return (
    <span {...attributes} contentEditable={false} className="page-number">
      {children}
    </span>
  );
};

export const withEditableVoids = (editor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === 'editable-void' ? true : isVoid(element);
  };

  return editor;
};

export const withTables = editor => {
  const { deleteBackward, deleteForward, insertBreak } = editor

  editor.deleteBackward = unit => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === 'table-cell',
      })

      if (cell) {
        const [, cellPath] = cell
        const start = Editor.start(editor, cellPath)

        if (Point.equals(selection.anchor, start)) {
          Transforms.insertText(editor, `  `);
        }
      }
    }

    deleteBackward(unit)
  }

  editor.deleteForward = unit => {
    const { selection } = editor

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === 'table-cell',
      })

      // if (cell) {
      //   const [, cellPath] = cell
      //   const end = Editor.end(editor, cellPath)

      //   if (Point.equals(selection.anchor, end)) {
      //     return
      //   }
      // }
    }

    deleteForward(unit)
  }

  editor.insertBreak = () => {
    const { selection } = editor

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === 'table',
      })

      // if (table) {
      //   Transforms.insertText(editor, '\n')
      // }


      if (table) {
        // const [, tablePath] = table;
        //   const end = Editor.end(editor, tablePath);
        //   if (Point.equals(selection.anchor, end)) {
        //     Transforms.insertText(editor, '\n');
        //   }
        return
      }
    }

    insertBreak()
  }

  return editor
}


export const HeaderVoid = ({ attributes, children }) => {
  const { headerText, setHeaderText } = useContext(HeaderContext);
  return (
    <div {...attributes} contentEditable={false}>
      <div className={'header headerEditable'} style={{display: 'none'}}>
        <input
          type="text"
          className="header-input"
          value={headerText}
          onChange={(e) => {
            setHeaderText(e.target.value);
          }}
        />
      </div>
      {children}
    </div>
  );
};

export const FooterVoid = ({ attributes, children }) => {
  const { footerText, setFooterText } = useContext(FooterContext);
  return (
    <div {...attributes} contentEditable={false}>
      <div className={'footer footerEditable'} style={{display: 'none'}}>
        <input
          type="text"
          className="header-input "
          value={footerText}
          onChange={(e) => {
            setFooterText(e.target.value);
          }}
        />
      </div>
      {children}
    </div>
  );
};
