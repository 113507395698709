/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { Box, Drawer, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import './ProjectTopDrawer.css';
import { ProjectsType } from 'src/utils/constants';
import { useFetch } from 'src/hooks/useFetch';
import { getCurrentUser, isEmpty } from 'src/utils/helpers';
import { useSnackbar } from 'notistack';
import { SERVER_BASE_URL } from 'src/config';
import { DESK, ROOT_PROJECTS } from 'src/routes/paths';
import { useLocation, useNavigate } from 'react-router';

const ProjectTopDrawer = ({ refreshData, defaultProject }, ref) => {
  const user = getCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const [createProject, loading] = useFetch('api/projects', ``, '', 'POST', false);

  const [projectState, setProjectState] = useState({
    name: '',
    type: ProjectsType.TVSeriesWithOutline, // [available types:- "TVSeriesWithOutline" and "Folder" ]
    ownerId: user?.id || null,
    drafts: [], // pass any json inside array
    contents: [], // pass any json inside array
    notes: [], // pass any json inside array
    comments: [], // pass any json inside array
    episodes: [], // pass any json inside array
  });

  useEffect(() => {
    if (user && isEmpty(projectState.ownerId)) {
      setProjectState({ ...projectState, ownerId: user?.id });
    }
  }, [user]);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [project, setProject] = useState(ProjectsType.TVSeriesWithOutline);
  const location = useLocation().pathname;
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    openDrawer,
    closeDrawer,
  }));

  useEffect(() => {
    if (defaultProject) {
      setProjectState(defaultProject);
    }
  }, [defaultProject]);

  // Function to open the Drawer
  const openDrawer = () => {
    setOpen(true);
  };

  // Function to close the Drawer
  const closeDrawer = () => {
    setOpen(false);
  };

  const handleBackdropClick = () => {
    // Close the drawer when clicking on the backdrop
    closeDrawer();
  };

  // eslint-disable-next-line consistent-return
  const handleCreateProject = async () => {
    try {
      if (!projectState.name || isEmpty(projectState.name)) {
        enqueueSnackbar('Project name is required', {
          variant: 'error',
        });
        return false;
      }
      if (defaultProject) {
        setUpdateLoading(true);
        const updateProjectEndpoint = `${SERVER_BASE_URL}/api/projects/${defaultProject?.id}`;
        const response = await fetch(updateProjectEndpoint, {
          method: 'PATCH',
          // headers: {
          //   'Content-Type': 'application/json',
          //   Authoization: `Bearer ${localStorage.getItem('accessToken') || ''}`,
          // },
          body: JSON.stringify(projectState),
        });
        const json = await response.json();
        if (json?.success) {
          setUpdateLoading(false);
          enqueueSnackbar(`Project updated successfully`, {
            variant: 'success',
          });
          setProjectState({
            name: '',
            type: ProjectsType.TVSeriesWithOutline,
            ownerId: user?.id || null,
            drafts: [],
            contents: [],
            notes: [],
            comments: [],
            episodes: [],
          });
          refreshData();
          closeDrawer();
        } else {
          setUpdateLoading(false);
          enqueueSnackbar('Somthing went wrong while updating a project', {
            variant: 'error',
          });
        }
      } else {
        const response = await createProject(projectState);
        if (response?.success) {
          enqueueSnackbar(`Project created successfully`, {
            variant: 'success',
          });
          setProjectState({
            name: '',
            type: ProjectsType.TVSeriesWithOutline,
            ownerId: user?.id || null,
            drafts: [],
            contents: [],
            notes: [],
            comments: [],
            episodes: [],
          });
          refreshData();
          closeDrawer();
          if (location === DESK) {
            navigate(ROOT_PROJECTS)
          }

        } else {
          enqueueSnackbar('Somthing went wrong while creating a project', {
            variant: 'error',
          });
        }
      }
    } catch (error) {
      enqueueSnackbar('Somthing went wrong while creating a project', {
        variant: 'error',
      });
      setUpdateLoading(false);
    }
  };
  return (
    <>
      <Typography
        onClick={openDrawer}
        sx={{
          '&:hover': { backgroundColor: location === DESK ? '#fe6d291a' : '#d0551c' },
          fontSize: '.875rem',
          backgroundColor: location === DESK ? '#00017806' : '#fe6d29',
          fontFamily: 'inter',
          color: location === DESK ? '#000' : '#fff',
          padding: '0.5rem 2rem',
          borderRadius: '.25rem',
          cursor:"pointer",
        }}
      >
        New Project
      </Typography>

      <Drawer
        anchor="top"
        open={open}
        onClose={closeDrawer}
        // disableBackdropTransition // Prevent background from being disabled
        PaperProps={{ style: { width: '100%', maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' } }}
        hideBackdrop
      >
        {open && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,

              zIndex: -99, // Behind the drawer content
            }}
            onClick={handleBackdropClick} // Close the drawer on backdrop click
          />
        )}

        <Box p={'30px'}>
          <Typography sx={{ fontSize: '1.25rem', fontFamily: 'inter' }}>New Project</Typography>

          <Box>
            <Box mt={'30px'}>
              <Typography sx={{ fontSize: '.875rem' }}>Project name</Typography>
              <input
                className="pro-input dark-text light-bg"
                value={projectState.name}
                onChange={(e) => setProjectState({ ...projectState, name: e.target.value })}
              />
            </Box>

            <Box display={'flex'} alignItems={'center'} mt={'30px'}>
              <Box
                p={'.4rem 2rem'}
                onClick={() => {
                  setProject(ProjectsType.TVSeriesWithOutline);
                  setProjectState({ ...projectState, type: ProjectsType.TVSeriesWithOutline });
                }}
                sx={{
                  color: project === ProjectsType.TVSeriesWithOutline ? '#fe6d29' : '',
                  backgroundColor: project === ProjectsType.TVSeriesWithOutline ? '#fe6d291a' : '',
                  '&:hover': { border: '1px solid #fe6d29', color: '#fe6d29' },
                  border: '1px solid transparent',
                  cursor: 'pointer',
                }}
                display={'flex'}
                alignItems={'center'}
                gap={'5px'}
              >
                <Iconify sx={{ fontSize: '20px', color: '#fe6d29' }} icon="jam:book" />
                <Typography>Tv series with outline</Typography>
              </Box>
              <Box
                p={'.4rem 2rem'}
                onClick={() => {
                  setProject(ProjectsType.Folder);
                  setProjectState({ ...projectState, type: ProjectsType.Folder });
                }}
                sx={{
                  color: project === ProjectsType.Folder ? '#fe6d29' : '',
                  backgroundColor: project === ProjectsType.Folder ? '#fe6d291a' : '',
                  border: '1px solid transparent',
                  '&:hover': { border: '1px solid #fe6d29', color: '#fe6d29' },
                  cursor: 'pointer',
                }}
                display={'flex'}
                alignItems={'center'}
                gap={'5px'}
              >
                <Iconify sx={{ fontSize: '15px', fontWeight: 'bold' }} icon="ph:folder-thin" />
                <Typography>Folder</Typography>
              </Box>
            </Box>

            <Box mt={'30px'} display={'flex'} alignItems={'center'} gap={'10px'}>
              <Box
                p={'0.5rem 2rem'}
                sx={{
                  cursor: 'pointer',
                  backgroundColor: '#fe6d29',
                  borderRadius: '.25rem',
                  '&:hover': { backgroundColor: '#d0551c' },
                }}
                onClick={() => {
                  if (loading) return;
                  handleCreateProject();
                }}
              >
                <Typography sx={{ fontSize: '.875rem', fontFamily: 'inter', color: '#fff' }}>
                  {loading || updateLoading
                    ? `${defaultProject ? 'Updating...' : 'Creating...'}`
                    : `${defaultProject ? 'Update project' : 'Create project'}  `}
                </Typography>
              </Box>
              <Box
                className="light-bg-hover"
                onClick={closeDrawer}
                p={'0.5rem 2rem'}
                sx={{ cursor: 'pointer', backgroundColor: '#00017806', '&:hover': { backgroundColor: '#fe6d291a' } }}
              >
                <Typography sx={{ fontSize: '.875rem', fontFamily: 'inter' }}>Cancel</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default forwardRef(ProjectTopDrawer);
