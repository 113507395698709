import * as React from 'react';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import Iconify from 'src/components/iconify';
import { Tooltip, Typography } from '@mui/material';

import './CommentsPlusPopover.css';
import { nameInitials } from 'src/utils/common';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 4,
};

export default function NotesPlusPopover({ handleComment }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [noteLabel, setNoteLabel] = React.useState('');
  const [commentText, setCommentText] = React.useState('');
  const localUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  return (
    <div>
      <Tooltip title="New Note" arrow>
        <Box onClick={handleOpen}>
          <Iconify icon="ic:baseline-plus" />
        </Box>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display={'flex'} alignItems={'center'}>
            <Box display={'flex'} alignItems={'center'}>
              <Box>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#20a6a6',
                    borderRadius: '50%',
                    color: '#fff',
                    fontSize: '.75rem',
                  }}
                >
                  {localUser?.avatar ? (
                    <img
                      src={localUser?.avatar}
                      alt="avatar"
                      style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                    />
                  ) : (
                    nameInitials(localUser?.name || 'Screnpla User')
                  )}
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: '100%', mr: 1, ml: 1 }}>
              <Box>
                <Typography className="alt-desc dark-text">Label</Typography>
                <input
                  className="input-style dark-bg dark-text"
                  value={noteLabel}
                  onChange={(e) => setNoteLabel(e.target.value)}
                />
              </Box>
              <Box>
                <Typography className="alt-desc dark-text">Description</Typography>
                <textarea
                  className="input-style dark-bg dark-text"
                  placeholder="..."
                  onChange={(e) => setCommentText(e.target.value)}
                  value={commentText}
                />
              </Box>
            </Box>
            <Box>
              <Box
                onClick={() => {
                  handleComment({
                    label: noteLabel,
                    description: commentText,
                  });
                  handleClose();
                }}
                sx={{ width: '30px', height: '30px', backgroundColor: '#fe6d29', borderRadius: '50%' }}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Iconify sx={{ color: '#fff' }} icon="majesticons:send" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
