import * as React from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Iconify from 'src/components/iconify';
import { Tooltip } from '@mui/material';
import './NavSearchModal.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 630,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 2,
};

const NavSearchModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
    <Tooltip title="Search" arrow>
            <Box onClick={handleOpen} className="scren-search">
      <Iconify  sx={{}} icon="tdesign:search" width={20} height={24} />
      </Box>
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display={'flex'} alignItems={'center'} gap={'10px'}>
            <input className="search-m-input dark-bg dark-text" placeholder="Search commands..." />
            <Tooltip title="Close" arrow>
              <Box>
                <Iconify onClick={handleClose} sx={{}} icon="tdesign:close" width={20} height={24} />
              </Box>
            </Tooltip>
          </Box>

          <Box sx={{ maxHeight: '400px', overflowY: 'auto' }}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                marginTop: '10px',
                backgroundColor: '#00017806',
                padding: '0.5rem',
                fontSize: '.875rem',
                borderRadius: '.25rem',
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Iconify sx={{}} icon="tdesign:search" width={20} height={24} />
                <Typography sx={{ color: '#fe6d29', fontSize: '.875rem', fontFamily: 'inter' }}>
                  Search document...
                </Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                <Box
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '#fe6d29',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '#fe6d29',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '#fe6d29',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>F</Typography>
                </Box>
              </Box>
            </Box>

            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                marginTop: '10px',
                padding: '0.5rem',
                fontSize: '.875rem',
                borderRadius: '.25rem',
                '&:hover': { backgroundColor: '#00017806' },
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Iconify sx={{}} icon="material-symbols:pageview-outline-rounded" width={20} height={24} />
                <Typography sx={{ color: '', fontSize: '.875rem', fontFamily: 'inter' }}>Close palette</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                <Box
                className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>K</Typography>
                </Box>
              </Box>
            </Box>

            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                marginTop: '10px',
                padding: '0.5rem',
                fontSize: '.875rem',
                borderRadius: '.25rem',
                '&:hover': { backgroundColor: '#00017806' },
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Iconify sx={{}} icon="iconoir:journal-page" width={20} height={24} />
                <Typography sx={{ color: '', fontSize: '.875rem', fontFamily: 'inter' }}>Open Script...</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>O</Typography>
                </Box>
              </Box>
            </Box>

            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                marginTop: '10px',
                padding: '0.5rem',
                fontSize: '.875rem',
                borderRadius: '.25rem',
                '&:hover': { backgroundColor: '#00017806' },
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Iconify sx={{}} icon="octicon:bold-16" width={20} height={24} />
                <Typography sx={{ color: '', fontSize: '.875rem', fontFamily: 'inter' }}>Bold</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>B</Typography>
                </Box>
              </Box>
            </Box>

            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                marginTop: '10px',
                padding: '0.5rem',
                fontSize: '.875rem',
                borderRadius: '.25rem',
                '&:hover': { backgroundColor: '#00017806' },
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Iconify sx={{}} icon="bi:chat" width={20} height={24} />
                <Typography sx={{ color: '', fontSize: '.875rem', fontFamily: 'inter' }}>Start a comment</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Alt</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>M</Typography>
                </Box>
              </Box>
            </Box>

            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                marginTop: '10px',
                padding: '0.5rem',
                fontSize: '.875rem',
                borderRadius: '.25rem',
                '&:hover': { backgroundColor: '#00017806' },
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Iconify sx={{}} icon="jam:strikethrough" width={20} height={24} />
                <Typography sx={{ color: '', fontSize: '.875rem', fontFamily: 'inter' }}>Strike-through</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>
                    {' '}
                    <Iconify sx={{ fontSize: '15px' }} icon="fluent:keyboard-shift-24-regular" />
                  </Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>M</Typography>
                </Box>
              </Box>
            </Box>

            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                marginTop: '10px',
                padding: '0.5rem',
                fontSize: '.875rem',
                borderRadius: '.25rem',
                '&:hover': { backgroundColor: '#00017806' },
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Iconify sx={{}} icon="clarity:italic-line" width={20} height={24} />
                <Typography sx={{ color: '', fontSize: '.875rem', fontFamily: 'inter' }}>Italic</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>I</Typography>
                </Box>
              </Box>
            </Box>

            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                marginTop: '10px',
                padding: '0.5rem',
                fontSize: '.875rem',
                borderRadius: '.25rem',
                '&:hover': { backgroundColor: '#00017806' },
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Iconify sx={{}} icon="wpf:aligncenter" width={20} height={24} />
                <Typography sx={{ color: '', fontSize: '.875rem', fontFamily: 'inter' }}>Align:center</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>
                    {' '}
                    <Iconify sx={{ fontSize: '15px' }} icon="fluent:keyboard-shift-24-regular" />
                  </Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>E</Typography>
                </Box>
              </Box>
            </Box>

            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                marginTop: '10px',
                padding: '0.5rem',
                fontSize: '.875rem',
                borderRadius: '.25rem',
                '&:hover': { backgroundColor: '#00017806' },
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Iconify sx={{}} icon="mdi:format-uppercase" width={20} height={24} />
                <Typography sx={{ color: '', fontSize: '.875rem', fontFamily: 'inter' }}>Upper-case</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>
                    {' '}
                    <Iconify sx={{ fontSize: '15px' }} icon="fluent:keyboard-shift-24-regular" />
                  </Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>U</Typography>
                </Box>
              </Box>
            </Box>

            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                marginTop: '10px',
                padding: '0.5rem',
                fontSize: '.875rem',
                borderRadius: '.25rem',
                '&:hover': { backgroundColor: '#00017806' },
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Iconify sx={{}} icon="iconoir:3d-select-solid" width={20} height={24} />
                <Typography sx={{ color: '', fontSize: '.875rem', fontFamily: 'inter' }}>Add connected beat</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>
                    {' '}
                    <Iconify sx={{ fontSize: '15px' }} icon="fluent:keyboard-shift-24-regular" />
                  </Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>Ctrl</Typography>
                </Box>
                <Box
                    className="light-bg"
                  sx={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#fe6d291a',
                    color: '',
                    padding: '.5rem',
                    borderRadius: '.25rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '600' }}>B</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default NavSearchModal;
