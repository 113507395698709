import * as React from 'react';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Iconify from 'src/components/iconify';

import Switch from '@mui/material/Switch';
import './AlternateDraftModal.css';
import styled from '@emotion/styled';
import { useSnackbar } from 'notistack';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  border: 'none',
  outline: 'none',
  p: 4,
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 20,
  height: 12,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 10,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(5px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(8px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#fe6d29',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#FFF',
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 8,
    height: 8,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export default function AlternateDraftModal({ position }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div>
      {position === 'top' && (
        <Typography mb={'5px'} mt={'10px'} variant="body1" sx={{ fontSize: '.9rem' }} onClick={handleOpen}>
          Create alternate draft
        </Typography>
      )}

      {position === 'sidebar' && (
        <Box
          onClick={handleOpen}
          sx={{ '&:hover': { backgroundColor: '#fe6d291a' }, padding: '4px', borderRadius: '5px', color: '#fe6d29' }}
          display={'flex'}
          alignItems={'center'}
          gap={'2px'}
        >
          <Iconify
            className="dark-light-text"
            icon="system-uicons:plus"
            sx={{ fontSize: '20px', fontWeight: '600', color: '#fe6d29 !important' }}
          />
          <Typography className="dark-light-text" sx={{ fontSize: '.75rem', color: '#fe6d29', fontWeight: '600' }}>
            Alternate draft
          </Typography>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h5 className="alt-heading dark-text">Create Alternate Draft</h5>
          <Box>
            <Typography className="alt-desc dark-text">Description</Typography>
            <input className="input-style dark-bg dark-text" />
          </Box>

          <Box mt={'2rem'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box display={'flex'} alignItems={'center'}>
              <p className="alt-changes dark-text">Start marking changes</p>
              <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
            </Box>

            <Box display={'flex'} alignItems={'center'} gap={'8px'}>
              <button className="alt-cancel dark-text" onClick={handleClose}>
                Cancel
              </button>
              <button
                className="alt-create"
                onClick={() => {
                  enqueueSnackbar('Alternate draft created', { variant: 'success' });
                  handleClose();
                }}
              >
                Create
              </button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
