/* eslint-disable */
import "./CommentedText.css";

import { activeCommentThreadIDAtom } from "../../../utils/CommentState";
import classNames from "classnames";
import { getSmallestCommentThreadAtTextNode } from "../../../utils/EditorCommentUtils";
import { useSlate } from "slate-react";
import { useRecoilState } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { Fragment } from "react";

export default function CommentedText(props) {
  const editor = useSlate();
  const { commentThreads, textNode, ...otherProps } = props;
  const [activeCommentThreadID, setActiveCommentThreadID] = useRecoilState(
    activeCommentThreadIDAtom
  );

  const onClick = () => {
    setActiveCommentThreadID(
      getSmallestCommentThreadAtTextNode(editor, textNode)
    );
  };

  
  return (
    <span >
      <span
        {...otherProps}
        className={classNames({
          comment: true,
          "is-active": commentThreads.has(activeCommentThreadID),
        })}
        onClick={onClick}
      >
        {props.children}
      </span>
      {/* <FontAwesomeIcon
        id={activeCommentThreadID}
        className="icon-main dropbtn comment-icon"
        icon={faMessage}
        onClick={onClick}
      /> */}
    </span>
  );
}
