/* global jsPDF */
import { Box, Divider, Popover, Tooltip, Typography } from '@mui/material';
import React from 'react';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CourierprimeDropdown from '../../courierprimedropdown/CourierprimeDropdown';
import convertToDoc from './convert-to-doc';
import { saveAs } from 'file-saver';
import jsPDF from "jspdf";
import {convertToFdx, convertToXML} from './convert-to-fdx';
import ZoomDropdownValue from '../../zoomdropdownpopover/ZoomDropdownValue';
import "./CourierPrimeCodeRegular-normal";
import { removeClasses } from 'src/utils/common';
import html2canvas from 'html2canvas';

const ExportPopover = ({ editor, scriptName, editorRef }) => {
  const [anchorElSettings, setAnchorElSettings] = React.useState(null);
  const [format, setFormat] = React.useState('PDF Format');
  const handleClickSettings = (event) => {
    setAnchorElSettings(event.currentTarget);
  };
  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };
  const openSettings = Boolean(anchorElSettings);
  const idSettings = openSettings ? 'simple-popover' : undefined;

  const downloadAs = async () => {
    try {
      if (format === 'PDF Format') {
        handleGeneratePdf();
      }
      if (format === 'Word Doc Format') {
        const doc = await convertToDoc(editor);
        saveAs(doc, `${scriptName}.docx`);
      }
      if (format === 'Final Draft Format') {
        const doc = await convertToFdx(editor);
        saveAs(doc, `${scriptName}.fdx`);
      }
      if (format === 'XML Format') {
        const doc = await convertToXML(editor);
        saveAs(doc, `${scriptName}.xml`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGeneratePdf = async () => {
    // eslint-disable-next-line new-cap
    // const doc = new jsPDF({
    //   format: 'a4',
    //   unit: 'px',
    // });

    // doc.setFontSize(120);

    const reportElement = editorRef.current;
    
    try {
      const canvas = await html2canvas(reportElement);
      const imgData = canvas.toDataURL("image/png");
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      const pdfBlob = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const printWindow = window.open(pdfUrl);

      // Cleanup the URL object
      setTimeout(() => URL.revokeObjectURL(pdfUrl), 10000);

      setTimeout(() => {
        printWindow?.print();
      }, 1000);

   
    } catch (error) {
      console.error("Error generating PDF:", error);
    }


    //-------------------------


    // const docc = document.querySelectorAll('.printable-div');

    // // Create a new array without modifying the original one
    // const doccWithoutFirstElement = Array.from(docc)
      
    //   .map((item) => {  
    //     const clonedItem = item.cloneNode(true); // Clone the element
    //     const classPrefixesToRemove = ['.header-page', '.footer-page', '.footerEditable', '.headerEditable'];
    //     classPrefixesToRemove.forEach((prefix) => {
    //       const tobeRemoved = clonedItem.querySelector(prefix);
    //     if (tobeRemoved) {
    //       tobeRemoved.remove();
    //     }
    //   });
    //   return clonedItem;
    //   });
      
    //   // remove the class from doccWithoutFirstElement which starts with 'comment'
    //   doccWithoutFirstElement.forEach((item) => {
    //   const classPrefixesToRemove = ['comment', 'suggestedMention'];
    //   classPrefixesToRemove.forEach((prefix) => {
    //     const tobeRemoved = item.querySelectorAll(`[class^="${prefix}"]`);
    //     tobeRemoved.forEach((item) => {
    //       item.classList.remove(item.classList[0]);
    //     });
    //   });
    //   const mention = item.querySelectorAll('[class^="suggestedMention"]');
    //     mention.forEach((item) => {
    //     item.classList.remove(item.classList[0]);
    //   });

    //   const svg = item.querySelectorAll('svg');
    //   svg.forEach((item) => {
    //     item.remove();
    //   });

    // });

     


    // // doc.html(...doccWithoutFirstElement, {
    // //   async callback(doc) {
    // //     await doc.save(`${scriptName}`);
    // //   },
    // //   x: 0, // Adjust the horizontal position as needed
    // //   y: 0, // Adjust the vertical position as needed
    // //   html2canvas: { scale: 0.5 }, // Adjust the scale if necessary
    
    // // });

    // const content = `
    // <style>
    //   .suggestedMention {
    //     background-color: none !important;
    //   }
    // </style>
    // ${doccWithoutFirstElement.map((item) => item.outerHTML).join('')}
    // `

    // // convert template string to html element
    // const parser = new DOMParser();
    // const parsedHtml = parser.parseFromString(content, 'text/html');
    // const parsedElement = parsedHtml.documentElement ;

    // // eslint-disable-next-line new-cap
    // const doc = new jsPDF({
    //   format: "a4",
    //   unit: "px",
    // });
    // doc.setFont("courier-prime, CourierNext, monospace, monospace");
    // doc.setFontSize(20);
    // doc.setTextColor(20);
    // doc.html(parsedElement, {
    //   callback(doc) {
    //     doc.save(`${scriptName}.pdf`);
    //   },
    //   x: 0,
    //   y: 0,
    //   html2canvas: { scale: 0.51 },
    //   autoPaging: 'text'
    // });


  };

  return (
    <>
      <Tooltip title="Export" arrow>
        <Box aria-describedby={idSettings} onClick={handleClickSettings} className="scren-settings">
          <div>
            <FileUploadOutlinedIcon />
          </div>
        </Box>
      </Tooltip>
      <Popover
        id={idSettings}
        open={openSettings}
        anchorEl={anchorElSettings}
        onClose={handleCloseSettings}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            width: '270px',
            maxWidth: 360,
            backgroundColor: '',
            // color: "white",
            padding: '18px',
            fontSize: '.875rem',
          }}
        >
          <Typography
            className="dark-text"
            mb={'15px'}
            variant="body1"
            sx={{ color: '#7b7c8d', fontSize: '.875rem', fontFamily: 'inter' }}
            onClick={handleCloseSettings}
          >
            Export Script
          </Typography>
          <Box
          sx={{display: 'none'}}>
          <ZoomDropdownValue scriptZoom={100} />
          </Box>
          <Divider />

          <Box
            className="dark-text"
            mt={'15px'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ fontSize: '.875rem', color: '#333', fontWeight: '600' }}
          >
            <p className="fontfam">Select Format</p>

            <CourierprimeDropdown
              name={'export-script'}
              onChange={(value) => {
                setFormat(value);
              }}
              option1={'PDF Format'}
              option2={'Final Draft Format'}
              option3={'XML Format'}
            />
          </Box>

          <Divider />
          <Box>
            <button
              className="export-cardbtn"
              style={{
                cursor: 'pointer',
                backgroundColor: '#fe6d29',
              }}
              onClick={() => downloadAs()}
            >
              Export Now
            </button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default ExportPopover;
