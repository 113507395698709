/* eslint-disable jsx-a11y/media-has-caption */
import { format } from "date-fns";
import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { nameInitials } from "../../../utils/common";
import { fToNow } from "src/utils/formatTime";
import { getCurrentUser } from "src/utils/helpers";


const useStyles = makeStyles((theme) => (

  {

    authorProfileCircle: {
      width: 20,
      height: 20,
      fontSize: 10,
      marginTop: 5,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
    },


  }));

export default function CommentRow({
  comment: { author, text, creationTime, audioMessage, audioURL }, selectedText
}) {
  const classes = useStyles();
  const user = getCurrentUser();
  return (
    <div className={"comment-row"}>
      <div className="comment-author-photo">
        {/* <i className="bi bi-person-circle comment-author-photo"></i> */}
        <Avatar
          className={classes.authorProfileCircle}
        >
          <span className={classes.authorProfileCircleFont}> {
            user?.avatar.length > 0 ?
              <img src={user?.avatar} alt="avatar" />
              : nameInitials(user?.name)
          } </span>
        </Avatar>
      </div>
      <div>
        <span className="comment-author-name">{author}  <span className="comment-creation-time">
          {fToNow(creationTime)}
        </span></span>
        {
          audioMessage ?
            <audio
              controls
              controlsList="nodownload noremoteplayback nofullscreen noplaybackrate"
              src={audioURL}
            >
              Your browser does not support the audio element.
            </audio>
            :
            <div className="comment-text">{text}</div>
        }
      </div>
    </div>
  );
}
