import { Box, MenuItem, MenuList, Popover,Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import '../navbar/EditorNavbar.css';
import Iconify from 'src/components/iconify';

const ZoomDropdownValue = ({scriptZoom}) => {
  const [anchorElZoom, setAnchorElZoom] = React.useState(null);

  const handleClickZoom = (event) => {
    setAnchorElZoom(event.currentTarget);
  };

  const handleCloseZoom = () => {
    setAnchorElZoom(null);
  };

  const openAllZoom = (anchorElZoom);
  const idZoom = openAllZoom ? 'simple-popover' : undefined;

  const [selectedOptionZoom, setSelectedOptionZoom] = useState(`${scriptZoom}%`); // Initial selected o
  const handleSelectOptionZoom = (option) => {
    // Handle the selected option here

    setSelectedOptionZoom(option);
    handleCloseZoom(); // Close the popover after selecting an option
  };

  useEffect(() => {

    setSelectedOptionZoom(`${scriptZoom}%`); // Initial selected o

  }, [scriptZoom]);


  useEffect(() => {
    // get all elements with the printablePageShadow class
    const printablePageShadows = document.querySelectorAll('.printablePageShadow');
  
    // Iterate over each element and update the zoom class
    printablePageShadows.forEach((element) => {
      const zoomClass = Array.from(element.classList).find((cls) => cls.startsWith('script-zoom-'));
  
      if (zoomClass) {
        element.classList.remove(zoomClass);
      }
      // remove % from the selectedOptionZoom
      const zoomValue = selectedOptionZoom.replace('%', '');
      element.classList.add(`script-zoom-${zoomValue}`);
    });
  }, [selectedOptionZoom]);
  

  return (
    <>
      <Box

        className="scren-zoomlvl"
        aria-describedby={idZoom}
        onClick={handleClickZoom}
        display={'flex'}
        alignItems={'center'}
        justifyContent={"space-between"}
        sx={{
          backgroundColor: '#00017806',
          fontSize: '.75rem',
          padding: ' .15rem .5rem',
          height:"100%",
          borderRadius: '.25rem',
        }}
      >
        <p>{selectedOptionZoom}</p>
        
        <Iconify sx={{}} icon="eva:arrow-ios-downward-fill" width={16} height={24} />
      </Box>

      <Popover
        id={idZoom}
        open={openAllZoom}
        anchorEl={anchorElZoom}
        onClose={handleCloseZoom}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            width: '200px',
            maxWidth: 360,
            backgroundColor: '',
            // color: "white",
            padding: '10px',
          }}
        >
          <MenuList>
            <MenuItem onClick={() => handleSelectOptionZoom('60%')}>
              <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                60%
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => handleSelectOptionZoom('70%')}>
              <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                70%
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => handleSelectOptionZoom('80%')}>
              <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                80%
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => handleSelectOptionZoom('90%')}>
              <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                90%
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => handleSelectOptionZoom('100%')}>
              <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                100%
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => handleSelectOptionZoom('110%')}>
              <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                110%
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => handleSelectOptionZoom('120%')}>
              <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                120%
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => handleSelectOptionZoom('130%')}>
              <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                130%
              </Typography>
            </MenuItem>
         
            <MenuItem onClick={() => handleSelectOptionZoom('140%')}>
              <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                140%
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => handleSelectOptionZoom('150%')}>
              <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                150%
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => handleSelectOptionZoom('160%')}>
              <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                160%
              </Typography>
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </>
  );
};

export default ZoomDropdownValue;
