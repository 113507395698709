import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Popover, Divider } from '@mui/material';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';

import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import AttributionOutlinedIcon from '@mui/icons-material/AttributionOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import SubjectIcon from '@mui/icons-material/Subject';
import Iconify from 'src/components/iconify';
import './VerticalTabs.css';
import '../navbar/EditorNavbar.css';
import OptionDropdown from './document/OptionDropdown';
import WritingPreferences from './writingpreferences/WritingPreferences';
import Profile from './profile/Profile';
import Billing from './billing/Billing';
import Notifications from './notifications/Notifications';
import DocumentInfo from './documentinfo/DocumentInfo';
import Production from './production/Production';
import PageLayout from './pagelayout/PageLayout';
import HeaderFooter from './headerfooter/HeaderFooter';
import ElementsSceneHeading from './elementssettings/sceneheading/ElementsSceneHeading';
import ElementsAction from './elementssettings/action/ElementsAction';
import ElementsCharacter from './elementssettings/character/ElementsCharacter';
import ElementsParenthetical from './elementssettings/parenthetical/ElementsParenthetical';
import Dialogue from './elementssettings/dialogue/Dialogue';
import Shot from './elementssettings/shot/Shot';
import Transition from './elementssettings/transition/Transition';
import StartOfAct from './elementssettings/startofact/StartOfAct';
import CustomElement from './addelement/CustomElement';
import EndOfAct from './endofact/EndOfAct';

function VerticalTabs({ script, refreshData }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [isElementToggled, setIsElementToggled] = useState(false);

  const [textVal, setTextVal] = useState('writing');

  const [nestedTextVal, setNestedTextVal] = useState('');

  const handleToggleElement = () => {
    setIsElementToggled(!isElementToggled);
  };
  return (
    <>
      <Box sx={{ width: '100%' }} className="vertical-tabs-container">
        <Box sx={{ maxHeight: '500px', overflowY: 'auto' }} width={'30%'}>
          <Box>
            <Divider />
          </Box>
          <Typography
            mb={'5px'}
            className="dark-text"
            sx={{ color: '#7b7c8d' }}
            ml={'6px'}
            fontSize={'1rem'}
            mt={'10px'}
          >
            Account
          </Typography>
          <Tabs
            TabIndicatorProps={{ sx: { display: 'none' } }}
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className="vertical-tabs"
            sx={{
              '& button': {
                marginRight: '20px',
                paddingLeft: '10px',
                minHeight: '5px',
                marginBottom: '10px',
                lineHeight: '1.5',
              },
              '& button .MuiBox-root': { marginRight: '20px', borderRadius: '5px' },
              '& button span': { marginRight: '12px' },
              '& button:hover': { backgroundColor: '#00017806' },
            }}
          >
            {/* <Tab disabled label="Account" /> */}

            <Tab
              onClick={() => setTextVal('writing')}
              sx={{
                borderRadius: '5px',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                width: '100%',
                marginRight: '20px',
              }}
              label={
                <Box
                  className="dark-bg-text "
                  sx={{
                    cursor: 'pointer',
                    width: '100%',
                    background: textVal === 'writing' ? '#fe6d2917' : 'transparent',
                    color: textVal === 'writing' ? '#fe6d29' : '#656667',
                    padding: '.5rem',
                    marginLeft: '2px',
                  }}
                  ml={'-6px'}
                  display={'flex'}
                  alignItems={'center'}
                  gap={'5px'}
                >
                  <RemoveRedEyeOutlinedIcon sx={{ fontSize: '15px' }} />
                  <Box sx={{ fontSize: '.875rem' }} className="dark-bg-text">
                    Writing Preferences
                  </Box>
                </Box>
              }
            />
            <Tab
              onClick={() => setTextVal('profile')}
              sx={{
                borderRadius: '5px',
                width: '100%',
                whiteSpace: 'nowrap',
                marginTop: '-10px',
                marginBottom: '-10px',
                '&.MuiTab-root:focus': {
                  outline: 'none',
                },
              }}
              label={
                <Box
                  className="dark-bg-text "
                  sx={{
                    padding: '.5rem',
                    width: '100%',
                    background: textVal === 'profile' ? '#fe6d2917' : 'transparent',
                    color: textVal === 'profile' ? '#fe6d29' : '#656667',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  gap={'5px'}
                >
                  <Person2OutlinedIcon sx={{ fontSize: '15px' }} />{' '}
                  <Box className="dark-bg-text " width={''} fontSize={'.875rem'}>
                    Profile
                  </Box>
                </Box>
              }
            />
            <Tab
              sx={{ borderRadius: '5px', width: '100%', display: 'none', marginTop: '-10px' }}
              onClick={() => setTextVal('billing')}
              label={
                <Box
                  className="dark-bg-text "
                  sx={{
                    width: '100%',
                    background: textVal === 'billing' ? '#fe6d2917' : 'transparent',
                    color: textVal === 'billing' ? '#fe6d29' : '#656667',
                    padding: '.5rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  gap={'5px'}
                >
                  <AddCardOutlinedIcon fontSize="20px" />{' '}
                  <Box width={''} sx={{ fontSize: '.875rem' }}>
                    Billing
                  </Box>
                </Box>
              }
            />
            <Tab
              onClick={() => setTextVal('notifications')}
              sx={{
                borderRadius: '5px',
                whiteSpace: 'nowrap',
                marginTop: '-10px',
                marginBottom: '-10px',
                width: '100%',
              }}
              label={
                <Box
                  className="dark-bg-text "
                  sx={{
                    width: '100%',
                    background: textVal === 'notifications' ? '#fe6d2917' : 'transparent',
                    color: textVal === 'notifications' ? '#fe6d29' : '#656667',
                    padding: '.5rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  gap={'5px'}
                >
                  <NotificationsOutlinedIcon fontSize="20px" />{' '}
                  <Box width={''} sx={{ fontSize: '.875rem' }}>
                    Notifications
                  </Box>
                </Box>
              }
            />
            <Box
              sx={{ padding: '0.5rem', marginTop: '-10px', cursor: 'pointer' }}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              // marginLeft={'10px'}
              mb={'10px'}
              mt={'10px'}
            >
              <Typography className="dark-bg-text" sx={{ color: '#656667', fontFamily: 'inter' }} fontSize={'.875rem'}>
                Document
              </Typography>
              <OptionDropdown />
            </Box>

            <Tab
              onClick={() => setTextVal('documentinfo')}
              sx={{
                borderRadius: '5px',
                whiteSpace: 'nowrap',
                marginTop: '-10px',
                marginBottom: '-10px',
                width: '100%',
              }}
              label={
                <Box
                  className="dark-bg-text "
                  sx={{
                    width: '100%',
                    background: textVal === 'documentinfo' ? '#fe6d2917' : 'transparent',
                    color: textVal === 'documentinfo' ? '#fe6d29' : '#656667',
                    padding: '.5rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  gap={'5px'}
                >
                  <AttributionOutlinedIcon fontSize="20px" />{' '}
                  <Box width={''} sx={{ fontSize: '.875rem' }}>
                    Document info
                  </Box>
                </Box>
              }
            />
            <Tab
              onClick={() => setTextVal('production')}
              sx={{ borderRadius: '5px', width: '100%', marginTop: '-10px' }}
              label={
                <Box
                  className="dark-bg-text "
                  sx={{
                    width: '100%',
                    background: textVal === 'production' ? '#fe6d2917' : 'transparent',
                    color: textVal === 'production' ? '#fe6d29' : '#656667',
                    padding: '.5rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  gap={'5px'}
                >
                  <WorkOutlineOutlinedIcon fontSize="20px" />{' '}
                  <Box width={''} sx={{ fontSize: '.875rem' }}>
                    Production
                  </Box>
                </Box>
              }
            />
            <Tab
              onClick={() => setTextVal('pagelayout')}
              sx={{
                borderRadius: '5px',
                whiteSpace: 'nowrap',
                marginTop: '-10px',
                marginBottom: '-10px',
                width: '100%',
              }}
              label={
                <Box
                  className="dark-bg-text "
                  sx={{
                    width: '100%',
                    background: textVal === 'pagelayout' ? '#fe6d2917' : 'transparent',
                    color: textVal === 'pagelayout' ? '#fe6d29' : '#656667',
                    padding: '.5rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  gap={'5px'}
                >
                  <SubjectIcon fontSize="20px" />{' '}
                  <Box width={''} sx={{ fontSize: '.875rem' }}>
                    Page Layout
                  </Box>
                </Box>
              }
            />
            <Tab
              onClick={() => setTextVal('headerfooter')}
              sx={{ whiteSpace: 'nowrap', width: '100%', marginTop: '-10px', borderRadius: '5px' }}
              label={
                <Box
                  className="dark-bg-text "
                  sx={{
                    width: '100%',
                    background: textVal === 'headerfooter' ? '#fe6d2917' : 'transparent',
                    color: textVal === 'headerfooter' ? '#fe6d29' : '#656667',
                    padding: '.5rem',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  gap={'5px'}
                >
                  <Person2OutlinedIcon fontSize="20px" />{' '}
                  <Box width={''} sx={{ fontSize: '.875rem' }}>
                    Headers and Footers
                  </Box>
                </Box>
              }
            />

            <Box
              className="dark-bg-text "
              onClick={(e) => {
                // setTextVal('element');
                handleToggleElement(e);
              }}
              sx={{ padding: '0.5rem', marginTop: '-10px', cursor: 'pointer' }}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              // marginLeft={'10px'}
              mb={'10px'}
              mt={'10px'}
            >
              <Box className=" " display={'flex'} gap={'3px'} alignItems={'center'}>
                <Iconify
                  className=" "
                  icon={isElementToggled ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                  width={16}
                  height={24}
                  sx={{ color: '#656667' }}
                />
                <Typography className="dark-bg-text " sx={{ color: '#656667', fontWeight: '600' }} fontSize={'.875rem'}>
                  Elements Settings
                </Typography>
              </Box>
            </Box>

            {/* scene heading */}
            {isElementToggled && (
              <Tab
                onClick={() => setNestedTextVal('firsttab')}
                sx={{ marginTop: '-10px', whiteSpace: 'nowrap', width: '100%' }}
                label={
                  <Box
                    className="dark-bg-text "
                    sx={{
                      width: '100%',
                      background: nestedTextVal === 'firsttab' ? '#fe6d2917' : 'transparent',
                      color: nestedTextVal === 'firsttab' ? '#fe6d29' : '#656667',
                      padding: '.5rem',
                    }}
                    display={'flex'}
                    alignItems={'center'}
                    // justifyContent={'flex-start'}
                    // gap={'5px'}
                  >
                    <Iconify sx={{}} icon="solar:gallery-wide-bold" />{' '}
                    <Box width={''} ml={'-15px'} sx={{ fontSize: '.875rem' }}>
                      Scene Heading
                    </Box>
                  </Box>
                }
              />
            )}

            {/* Action */}
            {isElementToggled && (
              <Tab
                onClick={() => setNestedTextVal('secondtab')}
                sx={{ marginTop: '-10px', whiteSpace: 'nowrap', width: '100%' }}
                label={
                  <Box
                    className="dark-bg-text "
                    sx={{
                      width: '100%',
                      background: nestedTextVal === 'secondtab' ? '#fe6d2917' : 'transparent',
                      color: nestedTextVal === 'secondtab' ? '#fe6d29' : '#656667',
                      padding: '.5rem',
                    }}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    // gap={'5px'}
                  >
                    <Iconify sx={{}} icon="bx:run" />

                    <Box ml={'-15px'} width={''} sx={{ fontSize: '.875rem' }}>
                      Action
                    </Box>
                  </Box>
                }
              />
            )}

            {/* character */}
            {isElementToggled && (
              <Tab
                onClick={() => setNestedTextVal('thirdtab')}
                sx={{ marginTop: '-10px', whiteSpace: 'nowrap', width: '100%' }}
                label={
                  <Box
                    className="dark-bg-text "
                    sx={{
                      width: '100%',
                      background: nestedTextVal === 'thirdtab' ? '#fe6d2917' : 'transparent',
                      color: nestedTextVal === 'thirdtab' ? '#fe6d29' : '#656667',
                      padding: '.5rem',
                    }}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                  >
                    <Iconify sx={{}} icon="fa6-solid:user" />

                    <Box width={''} ml={'-15px'} sx={{ fontSize: '.875rem' }}>
                      Character
                    </Box>
                  </Box>
                }
              />
            )}

            {/* parenthetical */}
            {isElementToggled && (
              <Tab
                onClick={() => setNestedTextVal('fourthtab')}
                sx={{ marginTop: '-10px', whiteSpace: 'nowrap', width: '100%' }}
                label={
                  <Box
                    className="dark-bg-text "
                    sx={{
                      width: '100%',
                      background: nestedTextVal === 'fourthtab' ? '#fe6d2917' : 'transparent',
                      color: nestedTextVal === 'fourthtab' ? '#fe6d29' : '#656667',
                      padding: '.5rem',
                    }}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    // gap={'5px'}
                  >
                    <Iconify sx={{}} icon="ph:brackets-round-bold" />{' '}
                    <Box width={''} ml={'-15px'} sx={{ fontSize: '.875rem' }}>
                      Parenthetical
                    </Box>
                  </Box>
                }
              />
            )}

            {/* dialogue */}
            {isElementToggled && (
              <Tab
                onClick={() => setNestedTextVal('Dialogue')}
                sx={{ marginTop: '-10px', whiteSpace: 'nowrap', width: '100%' }}
                label={
                  <Box
                    className="dark-bg-text "
                    sx={{
                      width: '100%',
                      background: nestedTextVal === 'Dialogue' ? '#fe6d2917' : 'transparent',
                      color: nestedTextVal === 'Dialogue' ? '#fe6d29' : '#656667',
                      padding: '.5rem',
                    }}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    // gap={'5px'}
                  >
                    <Iconify sx={{}} icon="eva:message-circle-fill" />
                    <Box width={''} ml={'-15px'} sx={{ fontSize: '.875rem' }}>
                      Dialogue
                    </Box>
                  </Box>
                }
              />
            )}

            {/* Shot */}
            {isElementToggled && (
              <Tab
                onClick={() => setNestedTextVal('Shot')}
                sx={{ marginTop: '-10px', whiteSpace: 'nowrap', width: '100%' }}
                label={
                  <Box
                    className="dark-bg-text "
                    sx={{
                      width: '100%',
                      background: nestedTextVal === 'Shot' ? '#fe6d2917' : 'transparent',
                      color: nestedTextVal === 'Shot' ? '#fe6d29' : '#656667',
                      padding: '.5rem',
                    }}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    // gap={'5px'}
                  >
                    <Iconify sx={{}} icon="ep:video-camera-filled" />{' '}
                    <Box ml={'-15px'} width={''} sx={{ fontSize: '.875rem' }}>
                      Shot
                    </Box>
                  </Box>
                }
              />
            )}

            {/* Transition */}
            {isElementToggled && (
              <Tab
                onClick={() => setNestedTextVal('Transition')}
                sx={{ marginTop: '-10px', whiteSpace: 'nowrap', width: '100%' }}
                label={
                  <Box
                    className="dark-bg-text "
                    sx={{
                      width: '100%',
                      background: nestedTextVal === 'Transition' ? '#fe6d2917' : 'transparent',
                      color: nestedTextVal === 'Transition' ? '#fe6d29' : '#656667',
                      padding: '.5rem',
                    }}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    // gap={'5px'}
                  >
                    <Iconify sx={{}} icon="fluent:slide-transition-20-filled" />{' '}
                    <Box ml={'-15px'} width={''} sx={{ fontSize: '.875rem' }}>
                      Transition
                    </Box>
                  </Box>
                }
              />
            )}

            {/* start of act */}
            {isElementToggled && (
              <Tab
                onClick={() => setNestedTextVal('startact')}
                sx={{ whiteSpace: 'nowrap', width: '100%' }}
                label={
                  <Box
                    className="dark-bg-text "
                    sx={{
                      marginTop: '-10px',
                      width: '100%',
                      background: nestedTextVal === 'startact' ? '#fe6d2917' : 'transparent',
                      color: nestedTextVal === 'startact' ? '#fe6d29' : '#656667',
                      padding: '.5rem',
                      paddingTop: '1rem',
                    }}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    gap={'2px'}
                  >
                    <Box />
                    <Box width={''} sx={{ fontSize: '.875rem' }}>
                      Start of Act
                    </Box>
                  </Box>
                }
              />
            )}

            {/* end of act */}
            {isElementToggled && (
              <Tab
                onClick={() => setNestedTextVal('endact')}
                sx={{ marginTop: '-10px', whiteSpace: 'nowrap', width: '100%' }}
                label={
                  <Box
                    className="dark-bg-text "
                    sx={{
                      width: '100%',
                      background: nestedTextVal === 'endact' ? '#fe6d2917' : 'transparent',
                      color: nestedTextVal === 'endact' ? '#fe6d29' : '#656667',
                      padding: '.5rem',
                    }}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    gap={'2px'}
                  >
                    <Box />{' '}
                    <Box width={''} sx={{ fontSize: '.875rem' }}>
                      End of Act
                    </Box>
                  </Box>
                }
              />
            )}

            {/* custiom element */}
            {/* {isElementToggled && (
              <Tab
                onClick={() => setNestedTextVal('Customelement')}
                sx={{ marginTop: '-10px', whiteSpace: 'nowrap', width: '100%' }}
                label={
                  <Box
                    className="dark-bg-text "
                    sx={{
                      width: '100%',
                      background: nestedTextVal === 'Customelement' ? '#fe6d2917' : 'transparent',
                      color: nestedTextVal === 'Customelement' ? '#fe6d29' : '#656667',
                      padding: '.5rem',
                    }}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                    // gap={'5px'}
                  >
                    <Iconify sx={{}} icon="ic:baseline-plus" />{' '}
                    <Box ml={'-15px'} width={''} sx={{ fontSize: '.875rem' }}>
                      Add element
                    </Box>
                  </Box>
                }
              />
            )} */}
          </Tabs>
        </Box>

        <Box maxHeight={'500px'} width={'70%'} sx={{ overflowY: 'auto' }}>
          <Box>
            <Divider />
          </Box>
          {/* writinig preferences */}
          <TabPanel value={value} index={0}>
            <WritingPreferences />
          </TabPanel>

          {/* Profile */}
          <TabPanel value={value} index={1}>
            <Profile />
          </TabPanel>
          {/* Billing */}
          {/* <TabPanel value={value} index={2}>
            <Billing />
          </TabPanel> */}

          {/* Notifications */}
          <TabPanel value={value} index={3}>
            <Notifications script={script} refreshData={refreshData} />
          </TabPanel>

          {/* Document info */}
          <TabPanel value={value} index={5}>
            <DocumentInfo script={script} refreshData={refreshData} />
          </TabPanel>

          {/* Production */}
          <TabPanel value={value} index={6}>
            <Production script={script} refreshData={refreshData} />
          </TabPanel>

          {/* Page Layout */}
          <TabPanel value={value} index={7}>
            <PageLayout script={script} refreshData={refreshData} />
          </TabPanel>

          {/* headerfooter */}
          <TabPanel value={value} index={8}>
            <HeaderFooter script={script} refreshData={refreshData} />
          </TabPanel>

          {/* elements-scene heading */}
          <TabPanel value={value} index={10}>
            <ElementsSceneHeading script={script} refreshData={refreshData} />
          </TabPanel>

          {/* elements-action */}
          <TabPanel value={value} index={11}>
            <ElementsAction script={script} refreshData={refreshData} />
          </TabPanel>

          {/* elements character */}
          <TabPanel value={value} index={12}>
            <ElementsCharacter script={script} refreshData={refreshData} />
          </TabPanel>
          {/* element-parenthetical */}
          <TabPanel value={value} index={13}>
            <ElementsParenthetical script={script} refreshData={refreshData} />
          </TabPanel>
          {/* element-dialogue */}
          <TabPanel value={value} index={14}>
            <Dialogue script={script} refreshData={refreshData} />
          </TabPanel>
          {/* shot */}
          <TabPanel value={value} index={15}>
            <Shot script={script} refreshData={refreshData} />
          </TabPanel>

          {/* Transition */}
          <TabPanel value={value} index={16}>
            <Transition script={script} refreshData={refreshData} />
          </TabPanel>

          {/* element-startofact */}
          <TabPanel value={value} index={17}>
            <StartOfAct script={script} refreshData={refreshData} />
          </TabPanel>
          {/* element-endofact */}
          <TabPanel value={value} index={18}>
            <EndOfAct script={script} refreshData={refreshData} />
          </TabPanel>
          {/* custom-elements */}
          <TabPanel value={value} index={19}>
            <CustomElement />
          </TabPanel>
        </Box>

    
      </Box>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className="vertical-tab-panel"
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default VerticalTabs;
