import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ImageSelector from './imageSelector';

// Ruler component to be rendered below the toolbar
const GettingStartedPage = ({ getStartedExpanded, setGetStartedExpanded, script, updateScriptApi }) => (

    <div
        className="getting-started-page"
        data-slate-node="element"
        style={{
            position: "relative",
            marginLeft: "auto",
            marginRight: "auto",
            // marginTop: 20,
            width: "8.27in",
            height: `${getStartedExpanded ? "5.69in" : "100px"}`,
            padding: 60,
            color: "rgb(51, 51, 51)",
            lineHeight: 12,
            fontSize: 12,
            fontWeight: 400,
            fontFamily: "CourierPrimeCodeRegular",
            transition: "height 500ms ease 0s, padding-top 500ms ease 0s",
            background: "rgb(255, 255, 255)"
        }}
    >
        <div contentEditable="false">
          {getStartedExpanded && <ImageSelector script={script} />}
        </div>
        <div style={{ lineHeight: 3 }}>
            <div>
                <div className="dropdown" />
                <div style={{ textAlign: "center" }}>
                    <input
                        type="text"
                        className="getting-started-element-div"
                        placeholder='Getting Started'
                        defaultValue={script?.subTitle || "Getting Started"}
                        onChange={(e) => {
                            const newSubtitle = e.target.value;
                            updateScriptApi({ subTitle: newSubtitle });
                        }
                        }
                    />

                </div>
            </div>
            {getStartedExpanded  && 
            <>
            <div>
                <div style={{ textAlign: "center" }}>
                    <input
                        type="text"
                        className="getting-started-element-div"
                        placeholder='Written By'
                        defaultValue={script?.writtenBy || "Written By"}
                        onChange={(e) => {
                            const writtenBy2 = e.target.value;
                            updateScriptApi({ writtenBy: writtenBy2 });
                        }
                        }
                    />

                </div>
            </div>
            <div>
                <div>
                    <div
                        data-slate-node="element"
                        className="getting-started-element-div"
                        style={{
                            textTransform: "uppercase",
                            justifyContent: "center",
                            marginTop: 10,
                            marginBottom: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            position: "relative"
                        }}
                    >
                        <span data-slate-node="text">
                            <span data-slate-leaf="true">
                                <span data-slate-string="true">
                                    {
                                        `${script?.owner?.name} | Author` || "Author"
                                    }
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            </>}
        </div>


        <FontAwesomeIcon
            className="gettingStartedToggle print-hide"
            icon={getStartedExpanded ? faAngleUp : faAngleDown}
            onClick={() => setGetStartedExpanded(!getStartedExpanded)}
        />
    </div>
);

export default GettingStartedPage;