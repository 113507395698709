/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useFetch } from 'src/hooks/useFetch';
import { getCurrentUser, isEmpty } from 'src/utils/helpers';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import './EditorNavbar.css';
import { Box, CircularProgress, Toolbar, Tooltip, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import { BlockButtonToolbar, DDButtonToolbar, MarkButtonToolbar } from 'src/utils/common';
import { useNavigate, useParams } from 'react-router';
import ShareModal from './sharemodal/ShareModal';
import { DESK } from '../../../../routes/paths';
import NavSearchModal from './navsearchmodal/NavSearchModal';
import SmSettingsPopper from './SmDevNavbar/smsettingspopover/SmSettingsPopper';
import SmTopDrawer from './SmDevNavbar/smtopdrawer/SmTopDrawer';
import SettingsPopover from './settingspopover/SettingsPopover';
import MainDraftPopover from './maindraftpopover/MainDraftPopover';
import NavChangePopover from './navchangepopover/NavChangePopover';
import ExportPopover from './exportpopover/exportpopover';
import {
  BoldTextTooltip,
  ItalicTextTooltip,
  alignCenterTextTooltip,
  alignLeftTextTooltip,
  alignRightTextTooltip,
  allCapsTextTooltip,
  capitalizeTextTooltip,
  doubleDialogueTextTooltip,
  highlightTextTooltip,
  justifyTextTooltip,
  quoteTextTooltip,
  strikeOutTextTooltip,
  underLineTextTooltip,
} from 'src/utils/constants';
import { saveAs } from 'file-saver';

const Navbar = ({ script, setComponentShow, refreshData, editor, saving, location, editorRef }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = getCurrentUser();
  const [updateScript] = useFetch(`api/scripts/${id}`, ``, '', 'PATCH', false);

  const [scriptName, setScriptName] = useState(script?.title || '');
  const [textState, setTextState] = useState('Script');

  useEffect(() => {
    if (script) {
      setScriptName(script.title || 'untitled');
    }
  }, [script]);

  const handleHome = () => {
    navigate(DESK);
  };

  const handleUpdateScript = async () => {
    try {
      if (scriptName === script.title || isEmpty(scriptName)) return;
      await updateScript({ title: scriptName });
      refreshData();
    } catch (error) {
      console.log(error);
    }
  };

  const saveFile = () => {
    const { children } = editor;
    console.log('children===>', children);
    const blob = new Blob([JSON.stringify(children)], { type: 'application/json' });
    saveAs(blob, `${scriptName}.scrn`);
  };

  return (
    <>
      <div className="scren-navbar">
        {/* nav first */}

        <div className="scren-icons">
          <Tooltip title="Go to desk" arrow>
            <div className="scren-home" onClick={handleHome}>
              <Iconify sx={{}} icon="fluent:home-24-filled" width={18} height={20} />
            </div>
          </Tooltip>
          <NavSearchModal />
          {
            location !== "Outline" &&

            <Toolbar className="toolbar">
            <MarkButtonToolbar format="bold" icon="format_bold" tooltip={BoldTextTooltip} />
            <MarkButtonToolbar format="italic" icon="format_italic" tooltip={ItalicTextTooltip} />
            <MarkButtonToolbar format="underline" icon="format_underlined" tooltip={underLineTextTooltip} />
            <MarkButtonToolbar format="highlight" icon="highlight" tooltip={highlightTextTooltip} />
            <MarkButtonToolbar format="strikeout" icon="strikethrough_s" tooltip={strikeOutTextTooltip} />
            <MarkButtonToolbar format="capitalize" icon="text_fields" tooltip={capitalizeTextTooltip} />
            <MarkButtonToolbar format="uppercase" icon="title" tooltip={allCapsTextTooltip} />
            <BlockButtonToolbar format="block-quote" icon="format_quote" tooltip={quoteTextTooltip} />
            <DDButtonToolbar tooltip={doubleDialogueTextTooltip} />
            <BlockButtonToolbar format="left" icon="format_align_left" tooltip={alignLeftTextTooltip} />
            <BlockButtonToolbar format="center" icon="format_align_center" tooltip={alignCenterTextTooltip} />
            <BlockButtonToolbar format="right" icon="format_align_right" tooltip={alignRightTextTooltip} />
            <BlockButtonToolbar format="justify" icon="format_align_justify" tooltip={justifyTextTooltip} />
            {/* <Button onClick={() => setShowColorPicker(!showColorPicker)}>
                      <IconToolbar>format_paint</IconToolbar>
                    </Button>
                    {showColorPicker && <ColorPicker onColorSelect={onColorSelect} />}

                    <Button
                      reversed
                      isActive={false}
                      disabled={!shouldAllowNewCommentThreadAtSelection(editor, selection)}
                      onMouseDown={onInsertComment}
                    >
                      <IconToolbar>comment</IconToolbar>
                    </Button> */}
          </Toolbar>}
        </div>

        {/* nav second */}

        <div className="scren-text">
          <div className="scren-upper">
            <input
              className="scren-new"
              placeholder="New"
              type="text"
              value={scriptName}
              onChange={(e) => {
                setScriptName(e.target.value);
              }}
              onBlur={handleUpdateScript}
            />
            <div className="scren-slash">/</div>
            <div className="scren-star">
              <Iconify sx={{}} icon="eva:star-outline" width={16} height={24} />
            </div>

            <MainDraftPopover />
          </div>

          <div className="scren-lower">
            <Typography
              fontSize={'0.67rem'}
              fontWeight={'600'}
              sx={{ color: location !== 'Outline' ? '#fe6d29' : '#b2b3c4' }}
              className="scren-script "
              onClick={() => {
                setComponentShow('Script');
                setTextState('Script');
              }}
            >
              Script
            </Typography>

            {/* <Typography
              sx={{ color: textState === 'Board' ? '#fe6d29' : '#b2b3c4' }}
              fontSize={'0.67rem'}
              fontWeight={'600'}
              className="scren-board"
              onClick={() => {
                setComponentShow('Board');
                setTextState('Board');
              }}
            >
              Board
            </Typography> */}

            <Typography
              fontSize={'0.67rem'}
              sx={{ color: location === 'Outline' ? '#fe6d29' : '#b2b3c4', fontWeight: '600' }}
              className="scren-outline "
              onClick={() => {
                setComponentShow('Outline');
                setTextState('Outline');
              }}
            >
              Outline
            </Typography>
          </div>
        </div>

        {/* nav last */}

        <div className="scren-contact">
          {saving && (
            <Tooltip title="Saving Changes" arrow>
              <Box>
                <CircularProgress size={20} sx={{ color: '#449552' }} />
              </Box>
            </Tooltip>
          )}
          {!saving && (
            <Tooltip title="Changes Saved" arrow>
              <Box>
                <Iconify sx={{ color: '#449552', fontSize: '16px' }} icon="simple-line-icons:check" />
              </Box>
            </Tooltip>
          )}
          {user?.avatar ? (
            <Box sx={{ position: 'relative' }}>
              <img src={user?.avatar} alt="avatar" className="scren-user" />
              <Box className="scren-userdot" />
            </Box>
          ) : (
            <Box className="scren-user" sx={{ textTransform: 'uppercase' }}>
              {user?.name?.charAt(0) + user?.name?.charAt(1)}
              <Box className="scren-userdot" />
            </Box>
          )}
          <Box sx={{ cursor: 'pointer' }} onClick={saveFile}>
            <button className="scren-save">Save File</button>
          </Box>
          <Box>
            <ShareModal script={script} refreshData={refreshData} />
          </Box>
          {/* <NavChangePopover /> */}

          <ExportPopover editor={editor} scriptName={scriptName} editorRef={editorRef} />
          <SettingsPopover script={script} refreshData={refreshData} />
        </div>

        <Box className={'sm-menu'} display={'flex'} alignItems={'center'} gap={'10px'}>
          <Iconify
            fontSize={'20px'}
            className="dark-text"
            sx={{ color: '#6d7175  ' }}
            icon="game-icons:multi-directions"
          />
          <Iconify fontSize={'20px'} className="dark-text" sx={{ color: '#d9dbde' }} icon="bi:chat" />
          {/* <SmTopDrawer /> */}
          <ExportPopover editor={editor} scriptName={scriptName} />
        </Box>

        <Box className={'sm-menu2'} display={'flex'} alignItems={'center'} gap={'10px'}>
          <Box>
            <SmSettingsPopper />
          </Box>
          <Box onClick={handleHome}>
            <Iconify className="dark-text" sx={{ color: '#000' }} icon="fluent:home-24-filled" fontSize={'20px'} />
          </Box>
          <Box
            mt={'-5px'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ width: '20px', height: '20px', backgroundColor: '#FFFFFF13', borderRadius: '50%' }}
          >
            <Iconify sx={{ color: '#20c997' }} icon="ic:baseline-check" />
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Navbar;
