import { useEffect, useState } from 'react';
// react query
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RecoilRoot, useRecoilSnapshot } from 'recoil';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components

import RtlLayout from './components/RtlLayout';

import ScrollToTop from './components/ScrollToTop';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';

import './styles/slick.css';
import './styles/App.css';
import './styles/DarkModeSwitcher.css';
import './styles/GettingStarted.css';
import { HeaderProvider } from './components/slate-editor/HeaderContext';
import { FooterProvider } from './components/slate-editor/FooterContext';
import useSettings from './hooks/useSettings';
import { changeEditorThemeMode } from './utils/common';
import { GoogleOAuthProvider } from '@react-oauth/google';
// ----------------------------------------------------------------------

export default function App() {
  // we can use this queryClient to make queries in any component
  // use default options for all queries in the application
  const queryClient = new QueryClient();

  const { themeMode } = useSettings();
  changeEditorThemeMode(themeMode);
  // process is not defined in the browser so we need to define it
  useEffect(() => {
    try {
      window.process = {
        ...window.process,
      };
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GG_CLIENT_ID || ""}>
      <ThemeProvider>
        <ThemeColorPresets>
            <RtlLayout>
              <NotistackProvider>
                <ScrollToTop />
                <HeaderProvider>
                  <FooterProvider>
                    <RecoilRoot>
                      <Router />
                      <DebugObserver />
                    </RecoilRoot>
                  </FooterProvider>
                </HeaderProvider>
              </NotistackProvider>
            </RtlLayout>
        </ThemeColorPresets>
      </ThemeProvider>
      </GoogleOAuthProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

function DebugObserver() {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    console.debug('The following atoms were modified:');
    // eslint-disable-next-line no-restricted-syntax
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return null;
}
