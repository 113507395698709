import { Box, Divider, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';
import Iconify from 'src/components/iconify';
import ViewDrawer from './ViewDrawer';

const CurrentSnapshotPopover = ({ name }) => {
  const [anchorElChange, setAnchorElChange] = React.useState(null);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const handleClickChange = (event) => {
    setAnchorElChange(event.currentTarget);
  };
  const handleCloseChange = () => {
    setAnchorElChange(null);
  };

  const handleCheckboxChange = (event) => {
    const newValue = event.target.checked;
    setCheckboxValue(newValue);
  };

  const handleClose = () => {
    setAnchorElChange(null);
  };

  const openChange = Boolean(anchorElChange);
  const idChange = openChange ? 'simple-popover' : undefined;

  return (
    <>
      <Box
        aria-describedby={idChange}
        onClick={handleClickChange}
        className={'left-history-dots'}
        sx={{ '&:hover': { width: '25x', height: '20px', backgroundColor: '#0000781e', borderRadius: '5px' } }}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Iconify icon="ph:dots-three-bold" sx={{ fontSize: '20px', color: '#7b7c8d' }} />
      </Box>

      <Popover
        sx={{ '& .MuiPaper-root': { borderRadius: '0px' } }}
        id={idChange}
        open={openChange}
        anchorEl={anchorElChange}
        onClose={handleCloseChange}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ border: '1px solid #ced4da' }}>
          {name === 'import' ? (
            <MenuList>
              <ViewDrawer />
              <MenuItem onClick={handleCloseChange}>
                <Typography sx={{ paddingRight: '2rem', paddingLeft: '.5rem', fontSize: '.875rem', fontWeight: '600' }}>
                  Edit name or revision color
                </Typography>
              </MenuItem>

              <Box my={'10px'}>
                <Divider />
              </Box>
              <MenuItem onClick={handleCloseChange}>
                <Typography sx={{ paddingRight: '2rem', paddingLeft: '.5rem', fontSize: '.875rem', fontWeight: '600' }}>
                  Compare to current version
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseChange}>
                <Typography sx={{ paddingRight: '2rem', paddingLeft: '.5rem', fontSize: '.875rem', fontWeight: '600' }}>
                  Compare with september 15,2023,2:36 am
                </Typography>
              </MenuItem>
            </MenuList>
          ) : (
            <MenuList>
              <MenuItem onClick={handleCloseChange}>
                <Typography sx={{ paddingRight: '2rem', paddingLeft: '.5rem', fontSize: '.875rem' }}>View</Typography>
              </MenuItem>
              <ViewDrawer />

              <Box my={'10px'}>
                <Divider />
              </Box>
              <MenuItem onClick={handleCloseChange}>
                <Typography sx={{ paddingRight: '2rem', paddingLeft: '.5rem', fontSize: '.875rem' }}>
                  Compare to current version
                </Typography>
              </MenuItem>
            </MenuList>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default CurrentSnapshotPopover;
