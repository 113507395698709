/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Box, Divider, Drawer, MenuItem, MenuList, Popover, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import { useNavigate, useParams } from 'react-router';
import '../RightDrawer/components/DrawerMenu.css';
import './LeftDrawer.css';
import Cardpopover from './components/Cardpopover';
import AlternateDraftModal from './components/AlternateDraftModal';
import SnapDrawer from './SnapshotDrawer/SnapDrawer';
import CurrentSnapshotPopover from './FullHistory/CurrentSnapshotPopover';
import CommentsPlusPopover from './components/CommentsPlusPopover';
import DraftRenameModal from './components/DraftRenameModal';

import SwitchButton from './components/SwitchButton';
import TagPopover from './components/TagPopover';
import InboxCards from './components/InboxCards';
import FliterBeatsPopover from './components/FliterBeatsPopover';

import ExpandNoteModal from './components/ExpandNoteModal';
import { nameInitials } from 'src/utils/common';
import { fToNow } from 'src/utils/formatTime';
import { useFetch } from 'src/hooks/useFetch';
import NotesPlusPopover from './components/NotesPlusPopover';
import CardNotePopover from '../RightDrawer/components/CardNotePopover';

const LeftDrawerMenu = ({ script }) => {
  const [open, setOpen] = useState(false);
  const [currentAll, setCurrentAll] = useState('current');
  const [history, setHistory] = useState();
  const [items, setItems] = useState([]);
  const [commentItems, setCommentItems] = useState([]);
  const localUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  const [commentViewSetting, setCommentViewSetting] = useState(false);
  const [inboxVal, setInboxVal] = useState(false);
  const [inboxCards, setInboxCards] = useState([{}]);
  const [showScenes, setShowScenes] = useState(false);
  const [showPreviews, setShowPreviews] = useState(false);
  const { id } = useParams();
  const [updateScriptApi] = useFetch(`api/scripts/${id}`, ``, '', 'PATCH', false);

  useEffect(() => {
    if (script && script.comments && script.comments.length > 0) {
      // return all comments from script.comments.map(comment => comment.contents.comments) to an array
      const comments = script.comments.map((comment) => comment.contents.comments);
      // flatten the array of arrays to a single array
      const flattenedComments = comments.flat();
      console.log(flattenedComments);
      setCommentItems(flattenedComments);
    }

    if (script && script.notes && script.notes.length > 0) {
      setItems(script.notes);
    }
  }, [script]);

  const handleCard = () => {
    setInboxCards((prevCards) => [...prevCards, {}]);
  };

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const handleBackdropClick = () => {
    closeDrawer();
  };

  const [tabValue, setTabValue] = useState('draft');

  // popover
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = anchorEl;
  const idpop = openpop ? 'simple-popover' : undefined;

  const handleNote = async (noteText) => {
    const scriptNotes = [];
    scriptNotes.push(...script.notes, {
      id: Math.random(),
      label: noteText?.label,
      description: noteText?.description,
      date: new Date(),
    });

    await updateScriptApi({ notes: scriptNotes });

    setItems((prevItems) => [
      ...prevItems,
      {
        id: Math.random(),
        label: noteText?.label,
        description: noteText?.description,
        date: new Date(),
      },
    ]);
  };

  const handleDelete = async (e, index) => {
    // delete index from the array
    
    const newItems = items.filter((item, i) => i !== index);
    await updateScriptApi({ notes: newItems });
    setItems(newItems);

  }
  const handleComment = async (commentText) => {
    const scriptComments = [];
    scriptComments.push(...script.comments, {
      state: 'hasValue',
      contents: {
        comments: [{ text: commentText, author: localUser?.name || 'screnpla-user', creationTime: new Date() }],
        id: 'threadID',
      },
    });
    await updateScriptApi({ comments: scriptComments });
    setCommentItems((prevItems) => [
      ...prevItems,
      { text: commentText, author: localUser?.name || 'screnpla-user', creationTime: new Date() },
    ]);
  };

  return (
    <>
      <Iconify onMouseEnter={openDrawer} onClick={openDrawer} rotate={3} icon="carbon:open-panel-top" />

      <Drawer
        anchor="left"
        open={open}
        onClose={closeDrawer}
        // onMouseLeave={closeDrawer}

        // disableBackdropTransition // Prevent background from being disabled
        PaperProps={{ style: { marginTop: '64px' } }} // Adjust the marginTop here
        hideBackdrop // Prevent backgroun
      >
        {open && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              //   backgroundColor: 'rgba(0, 0, 0, 0.5)', // Transparent background color
              zIndex: -99, // Behind the drawer content
            }}
            onClick={handleBackdropClick} // Close the drawer on backdrop click
          />
        )}

        <Box width={'330px'} p={'20px'} onMouseLeave={closeDrawer}>
          <Box gap={'15px'} display={'flex'} alignItems={'center'} justifyContent={''}>
            <Tooltip title="Unpin siderbar" arrow>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                width={'30px'}
                height={'30px'}
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.067)', borderRadius: '5px' }}
              >
                <Iconify
                  sx={{ fontSize: '20px', color: '', '&:hover': { color: '#fe6d29' } }}
                  icon="solar:pin-linear"
                />
              </Box>
            </Tooltip>
            <Box sx={{ cursor: 'pointer' }} display={'flex'} alignItems={'center'} gap={'10px'}>
              <Box
                onClick={() => setTabValue('draft')}
                textAlign={'center'}
                sx={{ color: '#7b7c8', '&:hover': { color: '#fe6d29' } }}
              >
                <Iconify
                  sx={{
                    fontSize: '18px',
                    marginBottom: '-5px',
                    color: tabValue === 'draft' ? '#fe6d29' : '#7b7c8',
                    '&:hover': { color: '#fe6d29' },
                  }}
                  icon="ph:files-duotone"
                />
                <Typography sx={{ fontSize: '.75rem', color: tabValue === 'draft' ? '#fe6d29' : '#7b7c8' }}>
                  Drafts
                </Typography>
              </Box>

              <Box
                onClick={() => setTabValue('content')}
                textAlign={'center'}
                sx={{ cursor: 'pointer', color: '#7b7c8', '&:hover': { color: '#fe6d29' } }}
              >
                <Iconify
                  sx={{
                    fontSize: '18px',
                    marginBottom: '-5px',
                    color: tabValue === 'content' ? '#fe6d29' : '#7b7c8',
                    '&:hover': { color: '#fe6d29' },
                  }}
                  icon="mdi:hamburger-menu"
                />
                <Typography sx={{ fontSize: '.75rem', color: tabValue === 'content' ? '#fe6d29' : '#7b7c8' }}>
                  Contents
                </Typography>
              </Box>

              <Box
                onClick={() => setTabValue('comment')}
                textAlign={'center'}
                sx={{ color: '#7b7c8', '&:hover': { color: '#fe6d29' } }}
              >
                <Iconify
                  sx={{
                    fontSize: '18px',
                    marginBottom: '-5px',
                    color: tabValue === 'comment' ? '#fe6d29' : '#7b7c8',
                    '&:hover': { color: '#fe6d29' },
                  }}
                  icon="mdi:message-bubble"
                />
                <Typography sx={{ fontSize: '.75rem', color: tabValue === 'comment' ? '#fe6d29' : '#7b7c8' }}>
                  Comments
                </Typography>
              </Box>

              <Box
                onClick={() => setTabValue('note')}
                textAlign={'center'}
                sx={{ color: '#7b7c8', '&:hover': { color: '#fe6d29' } }}
              >
                <Iconify
                  sx={{
                    fontSize: '18px',
                    marginBottom: '-5px',
                    color: tabValue === 'note' ? '#fe6d29' : '#7b7c8',
                    '&:hover': { color: '#fe6d29' },
                  }}
                  icon="grommet-icons:notes"
                />
                <Typography sx={{ fontSize: '.75rem', color: tabValue === 'note' ? '#fe6d29' : '#7b7c8' }}>
                  Notes
                </Typography>
              </Box>
            </Box>
          </Box>

          {tabValue === 'draft' && (
            <>
              {history === 'history' ? (
                <>
                  <Box mt={'20px'} display={'flex'} alignItems={'center'} gap={'10px'}>
                    <Box sx={{ cursor: 'pointer' }} onClick={() => setHistory('')}>
                      <Iconify
                        icon="eva:arrow-back-outline"
                        sx={{ fontSize: '15px', color: '#7b7c8d', fontWeight: '600' }}
                      />
                    </Box>
                    <Box>
                      <Box sx={{ color: '' }} gap={'2px'} display={'flex'} alignItems={'center'}>
                        <Iconify
                          sx={{ fontSize: '.5rem', color: '', marginTop: '-2px', '&:hover': { color: '#fe6d29' } }}
                          icon="bi:star"
                        />
                        <Typography fontSize={'.5rem'}>Main Draft</Typography>
                      </Box>
                      <Typography fontSize={''} sx={{ fontWeight: '600', fontSize: '.875rem', fontFamily: 'inter' }}>
                        Full History
                      </Typography>
                    </Box>
                  </Box>

                  <Box my={'10px'}>
                    <Divider />
                  </Box>

                  <Box>
                    <Box
                      className="left-history-box"
                      sx={{ '&:hover': { backgroundColor: '#fe6d291a' }, padding: '.35rem .5rem', borderRadius: '5px' }}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'flex-start'}
                    >
                      <Box display={'flex'} alignItems={'flex-start'} gap={'10px'}>
                        <Box
                          sx={{
                            width: '5px',
                            height: '5px',
                            backgroundColor: '#b2b3c3',
                            borderRadius: '50%',
                            marginTop: '7px',
                          }}
                        />
                        <Box>
                          <Typography
                            sx={{ fontWeight: '600', fontSize: '.75rem', fontFamily: 'inter', fontStyle: 'italic' }}
                          >
                            Current snapshot
                          </Typography>
                          <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                            <Box
                              sx={{ width: '5px', height: '5px', backgroundColor: '#00A6A6', borderRadius: '50%' }}
                            />
                            <Typography mt={'5px'} sx={{ color: '#7b7c8d', fontSize: '.67rem', fontFamily: 'inter' }}>
                              Jim Bulian
                            </Typography>
                            <Box
                              sx={{
                                width: '5px',
                                height: '5px',
                                backgroundColor: '#239620',
                                borderRadius: '50%',
                                marginTop: '8px',
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <CurrentSnapshotPopover name={'current'} />
                    </Box>

                    {/* autosaves */}
                    <Box
                      sx={{
                        color: '#7b7c8d',
                        fontSize: '.67rem',
                        fontFamily: 'inter',
                        padding: '.35rem .5rem',
                        paddingLeft: '25px',
                      }}
                      display={'flex'}
                      alignItems={'center'}
                      gap={'5px'}
                    >
                      <Box sx={{ width: '8px', height: '8px', backgroundColor: '#b2b3c3', borderRadius: '50%' }} />
                      <Typography sx={{ color: '#7b7c8d', fontSize: '.67rem', fontFamily: 'inter' }}>
                        28 autosave(s)
                      </Typography>
                    </Box>

                    <Box
                      className="left-history-box"
                      sx={{ '&:hover': { backgroundColor: '#fe6d291a' }, padding: '.35rem .5rem', borderRadius: '5px' }}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'flex-start'}
                    >
                      <Box display={'flex'} alignItems={'flex-start'} gap={'10px'}>
                        <Box
                          sx={{
                            width: '5px',
                            height: '5px',
                            backgroundColor: '#b2b3c3',
                            borderRadius: '50%',
                            marginTop: '7px',
                          }}
                        />
                        <Box>
                          <Typography sx={{ fontWeight: '600', fontSize: '.75rem', fontFamily: 'inter' }}>
                            Import
                          </Typography>
                          <Typography sx={{ color: '#7b7c8d', fontSize: '.67rem', fontFamily: 'inter' }}>
                            Last edit
                          </Typography>
                        </Box>
                      </Box>
                      <CurrentSnapshotPopover name={'import'} />
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box mt={'10px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography sx={{ fontSize: '.875rem', fontWeight: '600', paddingBottom: '10px' }}>
                      Drafts
                    </Typography>
                    <Box gap={'8px'} display={'flex'} alignItems={'center'}>
                      <Typography
                        onClick={() => setCurrentAll('current')}
                        sx={{
                          fontSize: '.875rem',
                          fontWeight: '600',
                          paddingBottom: '10px',
                          borderBottom: currentAll === 'current' ? '1px solid #fe6d29' : '',
                          color: currentAll === 'current' ? '#fe6d29' : '#7b7c8d',
                          cursor: 'pointer',
                        }}
                      >
                        Current
                      </Typography>
                      <Typography
                        onClick={() => setCurrentAll('all')}
                        sx={{
                          fontSize: '.875rem',
                          fontWeight: '600',
                          paddingBottom: '10px',
                          borderBottom: currentAll === 'all' ? '1px solid #fe6d29' : '',
                          color: currentAll === 'all' ? '#fe6d29' : '#7b7c8d',
                          cursor: 'pointer',
                        }}
                      >
                        All
                      </Typography>
                    </Box>
                  </Box>

                  {/* divider */}
                  <Box>
                    <Divider />
                  </Box>

                  <Box sx={{ borderRadius: '.5rem', marginTop: '10px', border: '1px solid #f8f7f7' }}>
                    {/* upper box */}
                    <Box
                      className="light-bg dark-text"
                      sx={{ backgroundColor: '#eae5f7', borderRadius: '.5rem .5rem 0 0' }}
                      p={'15px'}
                    >
                      <Box
                        mb={'3px'}
                        fontSize={'.75rem'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <Box
                          className="dark-text"
                          sx={{ color: '#fe6d29' }}
                          gap={'2px'}
                          display={'flex'}
                          alignItems={'center'}
                        >
                          <Iconify
                            sx={{ fontSize: '.75rem', color: '', marginTop: '-2px', '&:hover': { color: '#fe6d29' } }}
                            icon="bi:star"
                          />
                          <Typography className="dark-text" fontSize={'.75rem'}>
                            Main Draft
                          </Typography>
                        </Box>
                        <Box
                          className="dark-bg"
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          sx={{
                            color: '#fe6d29',
                            cursor: 'pointer',
                            width: '25px',
                            height: '25px',
                            '&:hover': { backgroundColor: '#fe6d2963', borderRadius: '5px' },
                          }}
                          aria-describedby={idpop}
                          onClick={handleClick}
                        >
                          <Iconify
                            className="dark-text dark-bg"
                            icon="ph:dots-three-bold"
                            sx={{ color: '#fe6d29', fontSize: '20px' }}
                          />
                        </Box>
                      </Box>
                      <Typography
                        className="dark-text"
                        sx={{ color: '#fe6d29', fontSize: '.875rem', fontWeight: '600' }}
                      >
                        Main Draft
                      </Typography>
                      <Typography className="dark-text" sx={{ color: '#fe6d29', fontSize: '.75rem' }}>
                        Created by you.Editing now...
                      </Typography>
                      <Box display={'flex'} alignItems={'center'}>
                        <Typography className="dark-text" sx={{ color: '#fe6d29', fontSize: '.75rem' }}>
                          Marking changes by writer
                        </Typography>

                        <Cardpopover />
                      </Box>
                    </Box>
                    {/* middle box */}
                    <Box
                      sx={{ padding: '10px', backgroundColor: '#00017806' }}
                      display={'flex'}
                      alignItems={'center'}
                      gap={'10px'}
                    >
                      <SnapDrawer position="sidebar" />

                      <AlternateDraftModal position="sidebar" />
                    </Box>
                    {/* lower box */}
                    <Box p={'20px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                      <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                        <Typography sx={{ fontSize: '.75rem', color: '#7b7c8d', fontWeight: '600' }}>
                          No previous Snapshots
                        </Typography>
                        <Tooltip
                          title="A snapshot captures and files the  current state of the doucment ,akin to a historical
                    bookmark,as you continue to edit your document ,you can track how it has changed  by comparing it  back to previous snapshots. "
                          arrow
                        >
                          <Box display={'flex'} alignItems={'center'}>
                            <Iconify icon="material-symbols:info-outline" sx={{ fontSize: '15px', color: '#7b7c8d' }} />
                          </Box>
                        </Tooltip>
                      </Box>
                      <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setHistory('history')}
                        display={'flex'}
                        alignItems={'center'}
                      >
                        <Typography sx={{ fontSize: '.75rem', color: '#7b7c8d', fontWeight: '600' }}>
                          Full history
                        </Typography>
                        <Iconify className="dark-light-text" icon="ri:arrow-right-s-line" />
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}

          {tabValue === 'content' && (
            <>
              <Box mt={'10px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Contents</Typography>
                <Tooltip title="inbox" arrow>
                  <Box
                    onClick={() => {
                      setInboxVal(!inboxVal);
                    }}
                    sx={{ '&:hover': { color: '#fe6d29' } }}
                    gap={'8px'}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <Typography sx={{ fontSize: '.75rem', color: '#7b7c8' }}>Inbox</Typography>
                    <Iconify icon="ep:message" />
                  </Box>
                </Tooltip>
              </Box>

              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={'10px'}>
                <Box display={'flex'} alignItems={'center'} gap={'20px'}>
                  <Tooltip title="Show beats" arrow>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{
                        width: '30px',
                        height: '30px',
                        '&:hover': { backgroundColor: '#00017806' },
                        borderRadius: '5px',
                      }}
                    >
                      <Iconify sx={{ '&:hover': { color: '#fe6d29' } }} icon="teenyicons:box-outline" />
                    </Box>
                  </Tooltip>
                  <Tooltip title="Show scenes" arrow>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{
                        width: '30px',
                        height: '30px',
                        '&:hover': { backgroundColor: '#00017806' },
                        borderRadius: '5px',
                      }}
                      onClick={() => setShowScenes(!showScenes)}
                    >
                      <Iconify sx={{ '&:hover': { color: '#fe6d29' } }} icon="solar:gallery-wide-broken" />
                    </Box>
                  </Tooltip>
                  <Tooltip title="Show previews" arrow>
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{
                        width: '30px',
                        height: '30px',
                        '&:hover': { backgroundColor: '#00017806' },
                        borderRadius: '5px',
                      }}
                      onClick={() => setShowPreviews(!showPreviews)}
                    >
                      <Iconify sx={{ '&:hover': { color: '#fe6d29' } }} icon="ci:hamburger-md" />
                    </Box>
                  </Tooltip>

                  <FliterBeatsPopover />
                </Box>
                <Box
                  sx={{ backgroundColor: '#00017806', padding: '.375rem', fontFamily: 'inter', borderRadius: '.25rem' }}
                >
                  <Typography sx={{ fontSize: '.75rem', fontWeight: '400' }}>Numbers...</Typography>
                </Box>
              </Box>

              <Box mt={'10px'}>
                <Divider />
              </Box>

              {inboxVal ? (
                <>
                  <Box display={'flex'} justifyContent={'flex-end'}>
                    <Box
                      sx={{
                        '&:hover': {
                          width: '40px',
                          height: '40px',
                          borderRadius: '5px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#00017806',
                        },
                        width: '40px',
                        height: '40px',
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onClick={() => {
                        handleCard();
                      }}
                    >
                      <Iconify icon="icon-park:plus" sx={{ fontSize: '20px', color: '#868e96' }} />
                    </Box>
                  </Box>

                  {inboxCards.map((e, i) => (
                    <Box mt={'10px'} key={i}>
                      <InboxCards />
                    </Box>
                  ))}
                </>
              ) : (
                <>
                  {showScenes === false && (
                    <>
                      <Box textAlign={'center'} mt={'15px'}>
                        <Typography sx={{ fontSize: '.75rem', color: '#b2b3c4', lineHeight: '1.2' }}>
                          Scenes and beats hidden. <br />
                          Consider enabling at least one of <br /> them on the menu above.
                        </Typography>
                      </Box>
                    </>
                  )}

                  {showScenes && (
                    <>
                      <Box
                        sx={{
                          '&:hover': { backgroundColor: '#a5d8ff' },
                          backgroundColor: '#fe6d291a',
                          padding: '0.375rem',
                          borderRadius: '5px',
                        }}
                        mt={'10px'}
                        display={'flex'}
                        alignItems={'center'}
                        gap={'5px'}
                      >
                        <Box p={'5px'} sx={{ color: '#b2b3c4', fontSize: '.75rem' }}>
                          1
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: '.875rem', fontFamily: 'courier-prime' }}>
                            INT. scrēnplā HQ - DAY
                          </Typography>
                          {showPreviews && (
                            <>
                              <Typography sx={{ fontSize: '.75rem', color: '#b2b3c4', fontFamily: 'courier-prime' }}>
                                TOBT (30s, s her, jumps_)
                              </Typography>
                            </>
                          )}
                        </Box>
                        <Box ml={'5px'} sx={{ fontSize: '.65rem', color: '#b2b3c4' }}>
                          5/8
                        </Box>
                      </Box>

                      <Box
                        sx={{ '&:hover': { backgroundColor: '#a5d8ff' }, padding: '0.375rem', borderRadius: '5px' }}
                        mt={'10px'}
                        display={'flex'}
                        alignItems={'center'}
                        gap={'5px'}
                      >
                        <Box p={'5px'} sx={{ color: '#b2b3c4', fontSize: '.75rem' }}>
                          1
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: '.875rem', fontFamily: 'courier-prime' }}>
                            EXT. Park - DAY
                          </Typography>
                          {showPreviews && (
                            <>
                              <Typography sx={{ fontSize: '.75rem', color: '#b2b3c4', fontFamily: 'courier-prime' }}>
                                TOBT (30s, s her, jumps_
                              </Typography>
                            </>
                          )}
                        </Box>
                        <Box ml={'5px'} sx={{ fontSize: '.65rem', color: '#b2b3c4' }}>
                          5/8
                        </Box>
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          )}

          {tabValue === 'comment' && (
            <>
              <Box mt={'10px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                  <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Comments</Typography>
                  <Box mt={'5px'}>
                    <Iconify icon="gg:arrow-up" />
                    <Iconify icon="gg:arrow-down" />
                  </Box>
                </Box>
                <Box gap={'8px'} display={'flex'} alignItems={'center'}>
                  <Tooltip title="Comment view settings" arrow>
                    <Box onClick={() => setCommentViewSetting(!commentViewSetting)}>
                      <Iconify icon="tdesign:map-connection" />
                    </Box>
                  </Tooltip>
                  <Tooltip title="Reload comments" arrow>
                    <Box>
                      <Iconify icon="ic:round-refresh" />
                    </Box>
                  </Tooltip>
                  <CommentsPlusPopover handleComment={handleComment} />
                </Box>
              </Box>

              {commentViewSetting && (
                <>
                  <Box>
                    <Box mt={'5px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography fontSize={'.75rem'}>Written by</Typography>
                      <Box>
                        <TagPopover name={'writtenby'} option1={'All accounts'} option2={'Jim Bulian'} />
                      </Box>
                    </Box>
                    <Box mt={'5px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography fontSize={'.75rem'}>Tag</Typography>
                      <Box>
                        <TagPopover name={'tag'} />
                      </Box>
                    </Box>
                    <Box mt={'5px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography fontSize={'.75rem'}>Only mentioning me </Typography>
                      <Box>
                        <SwitchButton />
                      </Box>
                    </Box>
                    <Box mt={'5px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography fontSize={'.75rem'}>Show resloved </Typography>
                      <Box>
                        <SwitchButton />
                      </Box>
                    </Box>
                    <Box mt={'5px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography fontSize={'.75rem'}>Show comments in deleted text </Typography>
                      <Box>
                        <SwitchButton />
                      </Box>
                    </Box>
                    <Box mt={'5px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography fontSize={'.75rem'}>Sort</Typography>
                      <Box>
                        <TagPopover name={'lastreply'} option1={'Last reply'} option2={'Date'} option3={'Page'} />
                      </Box>
                    </Box>
                  </Box>
                </>
              )}

              {commentItems.length > 0 ? (
                commentItems.map((item, index) => (
                  <Box key={index}>
                    <Box mt={'10px'}>
                      <Divider />
                    </Box>
                    <Box
                      className="left-drawer-comment-box"
                      sx={{
                        borderRadius: '5px',
                        '&:hover': { backgroundColor: '#00017806' },
                        paddingTop: '1rem',
                        paddingBottom: '0.5rem',
                        paddingLeft: '0.25rem',
                        paddingRight: '0.25rem',
                      }}
                      mt={'10px'}
                      display={'flex'}
                      alignItems={'flex-start'}
                      gap={'10px'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        sx={{
                          width: '30px',
                          height: '30px',
                          backgroundColor: '#20a6a6',
                          borderRadius: '50%',
                          color: '#fff',
                          fontSize: '.75rem',
                        }}
                      >
                        {localUser?.avatar ? (
                          <img
                            src={localUser?.avatar}
                            alt="avatar"
                            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                          />
                        ) : (
                          <Typography sx={{ fontSize: '.75rem', color: '#fff' }}>
                            {nameInitials(localUser?.name || 'screnpla-user')}
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <Typography
                          className="dark-text"
                          mb={'-5px'}
                          sx={{ color: '#333', fontSize: '.73rem', fontWeight: '600', fontFamliy: 'inter' }}
                        >
                          {item?.author}
                          {/* <span className="left-drawer-time">{fToNow(item?.creationTime)}</span> */}
                        </Typography>
                        {/* <Iconify sx={{ color: '#7b7c8d', fontSize: '12px', marginTop: '-14px' }} icon="bi:star" /> */}
                        <Typography sx={{ color: '#7b7c8d', fontSize: '.75rem', fontFamily: 'inter' }}>
                          {item?.text}
                        </Typography>
                      </Box>
                      {/* <Box
                        className="left-drawer-emoji dark-bg"
                        display={'flex'}
                        alignItems={'center'}
                        gap={'5px'}
                        sx={{
                          boxShadow: '1px 1px 5px rgba(0,0,0,0.5)',
                          borderRadius: '5px',
                          backgroundColor: '#fff',
                          padding: '0px 5px',
                          paddingTop: '5px',
                        }}
                      >
                        <Tooltip title="Resolve" arrow>
                          <Box>
                            <Iconify
                              sx={{ marginBottom: '0px!important', paddingBottom: '0px!important', color: '#7b7c8d' }}
                              icon="mdi:tick"
                            />
                          </Box>
                        </Tooltip>
                        <Box>
                          <Iconify
                            sx={{ marginBottom: '0px!important', paddingBottom: '0px!important', color: '#7b7c8d' }}
                            icon="fluent:emoji-32-regular"
                          />
                        </Box>
                        <Box>
                          <Iconify
                            sx={{ marginBottom: '0px!important', paddingBottom: '0px!important', color: '#7b7c8d' }}
                            icon="ph:hash-bold"
                          />
                        </Box>
                        <Box>
                          <Iconify
                            sx={{ marginBottom: '0px!important', paddingBottom: '0px!important', color: '#7b7c8d' }}
                            icon="ph:dots-three"
                          />
                        </Box>
                      </Box> */}
                    </Box>
                  </Box>
                ))
              ) : (
                <Box mt={'10px'}>
                  <Typography sx={{ fontSize: '.875rem', fontWeight: '600', color: '#7b7c8d' }}>No comments</Typography>
                </Box>
              )}
            </>
          )}

          {tabValue === 'note' && (
            <>
              <Box mt={'10px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography sx={{ fontSize: '.875rem', fontWeight: '600' }}>Notes</Typography>
                <NotesPlusPopover handleComment={handleNote} />
              </Box>

              {/* divider */}
              <Box mt={'10px'}>
                <Divider />
              </Box>

              {/* input */}
              <Box className="left-drawer-input-box dark-bg">
                <Iconify icon="iconamoon:search-light" />
                <input className="left-drawer-note-input dark-bg dark-text" placeholder="Search" />
              </Box>

              {items.length > 0 ? (
                items.map((e, i) => (
                  <Box
                    key={i}
                    className="left-hide-appear dark-bg"
                    sx={{ padding: '10px', borderRadius: '5px', '&:hover': { backgroundColor: '#f5f5f8' } }}
                    position={'relative'}
                    mt={'10px'}
                  >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                      <Typography sx={{ fontSize: '.875rem', fontWeight: '600', fontFamily: 'inter' }}>
                        {e.label}
                      </Typography>

                      <Box className="left-hide" display={'flex'} alignItems={'center'} gap={'5px'}>
                        <ExpandNoteModal item={e} />
                        <Box
                          sx={{
                            cursor: 'pointer',
                            width: '20px',
                            height: '20px',
                            '&:hover': { backgroundColor: '#00017806' },
                            borderRadius: '5px',
                          }}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        > 
                        <Iconify aria-describedby={id} onClick={(e) => {handleDelete(e, i)}} sx={{ color: '' }} icon="fluent:delete-20-regular" />

                          {/* <CardNotePopover /> */}
                        </Box>
                      </Box>
                    </Box>
                    <Typography
                      mt={'5px'}
                      sx={{ color: '#7b7c8d', fontSize: '.75rem', fontWeight: '600', fontFamily: 'inter' }}
                    >
                      {e.description.split(' ').slice(0, 5).join(' ')}
                      {e.description.split(' ').length > 5 ? '...' : ''}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Box mt={'10px'}>
                  <Typography sx={{ fontSize: '.875rem', fontWeight: '600', color: '#7b7c8d' }}>No notes</Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Drawer>

      <Popover
        id={idpop}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ borderRadius: '0px' }}
      >
        <Box sx={{ padding: '5px', borderRadius: '0px' }}>
          <MenuList>
            <DraftRenameModal myClose={handleClose} />

            <MenuItem onClick={handleClose}>
              <Typography variant="body1" sx={{ color: '#7b7c8d', fontSize: '.9rem' }}>
                Publish to collaborators
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Typography variant="body1" sx={{ fontSize: '.9rem' }}>
                Stop marking changes
              </Typography>
            </MenuItem>
            <MenuItem>
              <Typography variant="body1" sx={{ color: '#7b7c8d', fontSize: '.9rem' }}>
                Archive draft
              </Typography>
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </>
  );
};

export default LeftDrawerMenu;
