import { Box, Typography } from '@mui/material';
import React from 'react';
import { useSnackbar } from 'notistack';

import { useFetch } from 'src/hooks/useFetch';
import { useNavigate } from 'react-router';
import { getLogoPath, googleAuthenticator, verifyEmailAddress } from 'src/utils/common';
import { isValidEmail } from 'src/utils/helpers';
import { Signin, Welcome } from '../../../../../routes/paths';
import './Signup.css';
import { useGoogleLogin } from '@react-oauth/google';
import useAuth from 'src/hooks/useAuth';

const Signup = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const [email, setEmail] = React.useState(params.get('email') || '');
  const { login } = useAuth();
  const [callRegister] = useFetch(
    'api/auth/register',
    `Register in successfully`,
    'Something went wrong while registering',
    'POST',
    false
  );
  const { enqueueSnackbar } = useSnackbar();
  const [rloading, setRloading] = React.useState(false);
  const [getUser, loading] = useFetch(
    'api/auth/getUser',
    `Get user successfully`,
    'Something went wrong while getting the user',
    'POST',
    false
  );
  
  const handleClick = async () => {
    if (rloading) return;
    setRloading(true);
    if (isValidEmail(email) && !loading) {
      const response = await getUser({ email });
      if (response?.message === 'User not found!') {
        localStorage.setItem('email', email);
        await verifyEmailAddress({email});
        setRloading(false);
        navigate(Welcome);
      }
      else {
        enqueueSnackbar('Email already exists', {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar('Please enter a valid email address', {
        variant: 'error',
      });
    }
    
    setRloading(false);
  };

  const handleSignin = () => {
    navigate(Signin);
  };

  const googleRegister = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const { code } = codeResponse;
      const response = await googleAuthenticator(code);
      try {
        const { name, email, id } = response;
        const userResponse = await getUser({ email });
        console.log("userResponse", userResponse);
        if (userResponse?.message === 'User not found!') {
          const payload = {
            name,
            email: email.toString(),
            password: id,
            provider: 'google',
            avatar: null,
          };
          const resp = await callRegister(payload);
          if (resp?.success) {
            window.localStorage.clear();
            await login(email, id, enqueueSnackbar);
          } else {
            enqueueSnackbar('Something went wrong while creating the user', {
              variant: 'error',
            });
          }
        }
        await login(email, id, enqueueSnackbar);
   
      } catch (err) {
        console.log(err);
      }
    },
    flow: 'auth-code',
  });

  return (
    <>
      <Box mb={'10px'} py={'30px'} display={'flex'} justifyContent={'center'}>
        <img src={getLogoPath()} alt="" width={'200px'} />
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <Box p={'10px'} display={'flex'} alignItems={'center'} flexDirection={'column'} width={'440px'}>
          <Typography sx={{ fontSize: '1.52rem', fontFamily: 'inter', color: '' }}>Start writing for free</Typography>
          <Typography
            mt={'10px'}
            sx={{
              fontSize: '.875rem',
              color: '#b2b3c4',
              textAlign: 'center',
              lineHeight: '1.5',
            }}
          >
            Go ahead. Write the next blockbuster.
          </Typography>
          <Box mt={'20px'} width={'100%'}>
            <input
              className="signup-input"
              type="email"
              required
              placeholder="Email Address"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Box>
          <Typography
            onClick={handleClick}
            mt={'10px'}
            width={'100%'}
            textAlign={'center'}
            sx={{
              fontSize: '.875rem',
              color: '#fff',
              padding: '1rem 2rem',
              border: '1px solid #fe6d29',
              fontWeight: '400',
              backgroundColor: loading ? '#000178' : '#fe6d29',
              cursor: 'pointer',
              borderRadius: '.25rem',
              fontFamily: 'inter',
              '&:hover': { backgroundColor: '#d0551c' },
            }}
          >
            {(loading || rloading) ? 'Loading...' : 'Create an account'}
          </Typography>
          <Typography my={'15px'} sx={{ fontSize: '1rem', textAlign: 'center', fontWeight: '600', color: '' }}>
            or
          </Typography>
          {/* <Typography
            width={'100%'}
            mt={'10px'}
            sx={{
              fontSize: '.875rem',
              borderRadius: '.25rem',
              color: '',
              backgroundColor: '#00017806',
              padding: '.5rem 2rem',
              textAlign: 'center',
              fontWeight: '600',
              cursor: 'pointer',
            }}
          >
            Sign up with Apple
          </Typography> */}
          <Typography
            width={'100%'}
            mt={'10px'}
            onClick={() => googleRegister()}
            sx={{
              fontSize: '.875rem',
              borderRadius: '.25rem',
              color: '',
              backgroundColor: '#00017806',
              padding: '.5rem 2rem',
              textAlign: 'center',
              cursor: 'pointer',
              fontWeight: '600',
            }}
          >
            Continue with Google
          </Typography>
          <Typography
            className="link"
            mt={'20px'}
            sx={{ fontSize: '.875rem', textAlign: 'justify', color: '#b2b3c4', lineHeight: '1.5' }}
          >
            By creating an account or clicking "Sign up with Google" you acknowledge that you have read and agree with
            our <a href="https://screnpla.com/terms">Terms and Conditions </a> and <a href="https://screnpla.com/privacy"> Privacy Policy </a>.
          </Typography>
          <Typography mt={'20px'} sx={{ fontSize: '.875rem', color: '#b2b3c4' }}>
            Have an account?{' '}
            <a href="" className="signin" onClick={handleSignin}>
              {' '}
              Sign in{' '}
            </a>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Signup;
